import { consolidateStreamedStyles } from 'styled-components';
import {
    MARKETPLACES_LIST
    , MARKETPLACES_UPDATE_PARAMETROS
    , MARKETPLACES_ISSEARCHING

    , MARKETPLACES_PARCEIRO_CODPARC
    , MARKETPLACES_PARCEIRO_DADOS
    , MARKETPLACES_PARCEIRO_NOVACIDADE
    , MARKETPLACES_PARCEIRO_ISSAVING
    , MARKETPLACES_PARCEIRO_ISSEARCHING

    , MARKETPLACES_CHANGE_CAMPOS
    , MARKETPLACES_CONCI_LIST_IMPORTACOES
    , MARKETPLACES_CONCI_LOADER_IMPORTACOES
    , MARKETPLACES_CONCI_ISIMPORTING
    , MARKETPLACES_CONCI_IMPORT
    , MARKETPLACES_CONCI_ISPROCESSING
    , MARKETPLACES_CONCI_ISLISTINGREPASSE
    , MARKETPLACES_CONCI_LIST_REPASSE
    , MARKETPLACES_CONCI_IMPORT_CHANGE
    , MARKETPLACES_DEVOLUCOES_ACTION
    , MARKETPLACES_DEVOLUCOES_LOADING_ACTION
    , MARKETPLACES_DEVOLUCOES_CRIA_LOADING_ACTION
    , MARKETPLACES_DEVOLUCOES_MODAL_INFO_LOADING_ACTION
    , MARKETPLACES_DEVOLUCOES_ANALISAR_INFO
    , MARKETPLACES_DEVOLUCOES_ANALISAR_INFO_LOADING
    , MARKETPLACES_DEVOLUCOES_LOADING_LIST
    , MARKETPLACES_DEVOLUCOES_LOADING_ANALISAR
} from '../actions/ActionsTypes';
import { Buffer } from 'buffer';
import * as Api from '../utils/API';




export const marketplacesConciliacaoImportHandleChangeAction = (nurepassemkp, campo, valor) => {
    return {
        type: MARKETPLACES_CONCI_IMPORT_CHANGE
        , field: campo
        , payload: valor
        , nurepassemkp
    }
}

export const marketplacesConciliacaoHandleChangeAction = (event) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: MARKETPLACES_CHANGE_CAMPOS, field: event.target.name, payload: event.target.value })
        resolve([])
    });



export const marketplacesConciliacaoRepasseAction = (nurepassemkp, dtdeposito) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: MARKETPLACES_CONCI_IMPORT_CHANGE, field: 'isprocessandobaixa', payload: true, nurepassemkp: nurepassemkp })

        let entidade = {
            nurepassemkp: nurepassemkp
            , dtdeposito: dtdeposito
        };

        Api.postMarketPlacesConciliacaoRepasse(entidade).then(dados => {

            //atualiza lista de importacoes
            dispatch(marketplacesConciliacaoListImportacoesAction());

            dispatch({ type: MARKETPLACES_CONCI_IMPORT_CHANGE, field: 'isprocessandobaixa', payload: false, nurepassemkp: nurepassemkp })

            resolve(dados)
        })

    });



export const marketplacesConciliacaoDeleteAction = (nurepassemkp) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: MARKETPLACES_CONCI_IMPORT_CHANGE, field: 'isloadingdelete', payload: true, nurepassemkp: nurepassemkp })
        let entidade = {
            nurepassemkp: nurepassemkp
        };
        Api.postMarketPlacesConciliacaoDelete(entidade).then(dados => {
            dispatch(marketplacesConciliacaoListImportacoesAction());
            dispatch({ type: MARKETPLACES_CONCI_IMPORT_CHANGE, field: 'isloadingdelete', payload: false, nurepassemkp: nurepassemkp })
            resolve(dados)
        })

    });



export const marketplacesConciliacaoListRepasseAction = (nurepassemkp) => (dispatch) =>
    new Promise(function (resolve, reject) {

        //alert('list nurepasse:'+nurepassemkp)
        dispatch({ type: MARKETPLACES_CONCI_ISLISTINGREPASSE, payload: nurepassemkp, acao: true });

        dispatch({ type: MARKETPLACES_CONCI_LIST_REPASSE, concirepasse: [], concirepasselog: [] })

        Api.getMarketPlacesConciliacaoDetalhes(nurepassemkp).then(dados => {

            dispatch({ type: MARKETPLACES_CONCI_LIST_REPASSE, concirepasse: dados[0].concirepasse, concirepasselog: dados[0].concirepasselog })

            dispatch({ type: MARKETPLACES_CONCI_ISLISTINGREPASSE, payload: nurepassemkp, acao: false });

            resolve([])
        })



    });


export const marketplacesConciliacaoProcessImportacoesAction = (nurepassemkp) => (dispatch) =>
    new Promise(function (resolve, reject) {


        dispatch({ type: MARKETPLACES_CONCI_ISPROCESSING, payload: nurepassemkp, acao: true });


        // alert('nurepasse:' + nurepassemkp)

        let entidade = {
            nurepassemkp: nurepassemkp
        }

        Api.postMarketPlacesConciliacaoProcess(entidade).then(dados => {

            //atualiza lista de importacoes
            dispatch(marketplacesConciliacaoListImportacoesAction());

            dispatch({ type: MARKETPLACES_CONCI_ISPROCESSING, payload: nurepassemkp, acao: false });

            resolve(dados)
        })


        //Esta parte faz com que o loading do processo seja cancelado, *NÃO DESCOMENTAR*
        // dispatch({ type: MARKETPLACES_CONCI_LOADER_IMPORTACOES, payload: true })

        // Api.getMarketPlacesConciliacaoImportacoes().then(dados => {

        //     for (let i of dados) {
        //         i.isprocessing = false;
        //     }

        //     dispatch({ type: MARKETPLACES_CONCI_LIST_IMPORTACOES, payload: dados })
        //     dispatch({ type: MARKETPLACES_CONCI_LOADER_IMPORTACOES, payload: false })

        //     resolve([])
        // })


    });

export const marketplacesConciliacaoListImportacoesAction = (event) => (dispatch) =>
    new Promise(function (resolve, reject) {

        let dados = [];



        dispatch({ type: MARKETPLACES_CONCI_LOADER_IMPORTACOES, payload: true })

        Api.getMarketPlacesConciliacaoImportacoes().then(dados => {

            for (let i of dados) {
                i.isprocessing = false;
                i.islistingrepasse = false;
                i.isprocessandobaixa = false;
                i.isloadingdelete = false;
            }

            dispatch({ type: MARKETPLACES_CONCI_LIST_IMPORTACOES, payload: dados })
            dispatch({ type: MARKETPLACES_CONCI_LOADER_IMPORTACOES, payload: false })

            resolve([])
        })



    });


//método para gravar
export const marketplacesConciliacaoImportAction = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {
        const { concimktplaceid, conciarquivojson, conciarquivobuffer, conciarquivonome, conciisimporting } = getState().MarketPlacesReducer;
        const { login, userid } = getState().LoginReducer;
        // alert("ola")

        if (concimktplaceid != '0'
            //&& conciisimporting==false
        ) {

            let dados = [];
            let pedidosFiltradosArray = [];
            dispatch({ type: MARKETPLACES_CONCI_ISIMPORTING, payload: true });

            //LEROY MERLIN###########################################################
            if (concimktplaceid == '220') {
                //alert('Importar Leroy' + concimktplaceid);
                let pedidos = []

                //distinct de pedidos-----------------------------------------
                for (let i of conciarquivojson) {
                    pedidos.push({ pedido: i.pedido });
                }
                pedidos = [...new Set(pedidos.map(o => JSON.stringify(o)))];
                pedidos = pedidos.map(o => JSON.parse(o));
                //------------------------------------------------------------

                //percorrer cada pedido
                for (let i of pedidos) {
                    //resgata todos dados de cada pedido
                    let conteudo = conciarquivojson.filter(f => f.pedido === i.pedido);

                    //somando itens com tipo similares no mesmo pedido------------------
                    let obj = {}

                    conteudo.forEach((item) => {
                        if (obj[item.tipo]) {
                            obj[item.tipo].valor = obj[item.tipo].valor + item.valor
                        } else {
                            obj[item.tipo] = item
                        }
                    })

                    //------------------------------------------------------------------

                    //let valuesArr = Object.values(obj)
                    //gravando cada linha de todos pedidos no array principal
                    for (let linha of Object.values(obj)) {
                        dados.push(linha);
                    }

                }

                //Remover registros onde o campo valor estão com valor 0.00
                dados = dados.filter(f => f.valor > 0);

                //Remover registros onde não tenham número de transação/pedido
                //dados = dados.filter(f => f.pedido != null);

                //Colocando 99 para gistros onde não tem transacao/pedido
                for (let p of dados) {
                    if (p.pedido == undefined) {
                        p.pedido = 0;
                    }
                }



                // console.log(dados);

            }
            //MAGALU (MAGAZINE LUIZA)################################################
            else if (concimktplaceid == '182') {
                let pedidos = []

                // console.log("## ARQUIVO JSON ##", conciarquivojson)

                for (let i of conciarquivojson) {
                    pedidos.push({ pedido: i.pedido });
                }

                pedidos = [...new Set(pedidos.map(o => JSON.stringify(o)))];
                pedidos = pedidos.map(o => JSON.parse(o));

                let conteudo = conciarquivojson
                let conteudoestorno = conciarquivojson
                // console.log('## CONTEUDO ##', conteudo);
                //somando itens com tipo similares no mesmo pedido------------------
                let obj = []
                let pedidosAcumulados = {};

                conteudoestorno.forEach((item) => {
                    if (item.tipo === "Estorno") {
                        if (pedidosAcumulados[item.pedido]) {
                            pedidosAcumulados[item.pedido].valor += Math.abs(item.valor);
                        } else {
                            pedidosAcumulados[item.pedido] = {
                                parc_atual: null,
                                parc_total: null,
                                pedido: item.pedido,
                                taxa: 0,
                                tipo: item.tipo,
                                valor: parseFloat((Math.abs(item.valor) * 100).toFixed(2)) / 100,
                            };
                        }
                    } else if (item.tipo === "Transferência") {
                        let taxa = parseFloat(item.taxa_ted).toFixed(2).replace(/\.(\d)$/, ".$10");
                        // Use o número do pedido original como chave
                        pedidosAcumulados[item.pedido] = {
                            pedido: item.pedido, // Sem adicionar 1 ao número do pedido
                            tipo: item.tipo,
                            parc_atual: '1',
                            parc_total: null,
                            taxa: parseFloat(taxa),
                            valor: parseFloat((Math.abs(item.valor) * 100).toFixed(2)) / 100,
                        };
                    }
                });


                for (let i of conteudo) {
                    // Verificar se o pedido e a parcela atual existem no array dados
                    let indice = obj.findIndex(d => d.pedido === i.pedido && d.parc_atual === i.parc_atual && d.tipo != "Estorno" && d.tipo != "Evento");
                    // Se o pedido e parcela atual já existirem no array dados, somar os valores
                    if (indice !== -1) {
                        obj[indice].valor += i.valor;
                        //   console.log("O valor do pedido", i.pedido, "e parcela", i.parc_atual, "foi somado");
                    } else { // Caso contrário, incluir o objeto no array dados
                        obj.push(i);
                        //   console.log("O objeto", i, "foi incluído no array dados");
                    }
                }

                //------------------------------------------------------------------

                //gravando cada linha de todos pedidos no array principal
                for (let linha of Object.values(obj)) {
                    // console.log(linha)
                    dados.push(linha);
                }

                for (let pedidoAcumulado of Object.values(pedidosAcumulados)) {
                    // Encontre o pedido correspondente nos dados existentes
                    let pedidoExistente = dados.find(item => item.pedido === pedidoAcumulado.pedido);
                    // console.log(dados.find(item => item.pedido === pedidoAcumulado.pedido))
                    if (pedidoAcumulado.pedido > 0) {
                        // Se o pedido existir nos dados existentes, atualize o valor
                        if (pedidoExistente) {
                            dados.valor += pedidoAcumulado.valor
                            // pedidoExistente.valor += pedidoAcumulado.valor;
                        } else {
                            console.log('caiu no else')
                            // Se o pedido não existir nos dados existentes, adicione-o
                            dados.push(pedidoAcumulado);
                        }
                    }

                }



                // dados = dados.filter(f => f.valor > 0);

                //Remover registros onde não tenham número de transação/pedido
                //dados = dados.filter(f => f.pedido != null);

                //Colocando 99 para gistros onde não tem transacao/pedido
                for (let p of dados) {
                    if (p.pedido == undefined) {
                        p.pedido = 0;
                    }
                }
                let pedidosFiltrados = [];
                // console.log(dados)
                // Iterar sobre os dados
                for (let i of dados) {
                    // Verificar se o pedido é do tipo "Estorno"
                    if (i.tipo === "Estorno") {
                        let indice = pedidosFiltrados.findIndex(d => d.pedido === i.pedido && d.tipo === i.tipo);
                        // Se o pedido já existe no array pedidosFiltrados, soma o valor do estorno
                        if (indice !== -1) {
                            pedidosFiltrados[indice].valor += i.valor;
                        } else {
                            // Se não existe, adiciona o estorno ao array pedidosFiltrados
                            pedidosFiltrados.push(i);
                        }
                    } else {
                        // Verificar se há estorno para o mesmo pedido no array pedidosFiltrados
                        let indiceEstorno = pedidosFiltrados.findIndex(d => d.pedido === i.pedido && d.tipo === "Estorno" && i.tipo === "Estorno");
                        // Se não houver estorno para o mesmo pedido, adiciona o pedido ao array pedidosFiltrados
                        if (indiceEstorno === -1) {
                            pedidosFiltrados.push(i);
                        }
                    }
                }
                // Converta os valores do objeto pedidosFiltrados em um array
                pedidosFiltradosArray = Object.values(pedidosFiltrados);
                let countPedEvent = 0;
                for (let i of pedidosFiltrados) {
                    if (i.tipo === "Evento") {
                        countPedEvent += 1; // Atualiza o valor de countPedEvent
                        i.pedido = countPedEvent; // Atribui o valor atualizado ao pedido
                    }
                    if (i.tipo === "Transferência") {
                        i.pedido = 1;
                        i.taxa = Number(i.taxa_ted);
                    }
                }

            }
            //MADEIRA MADEIRA =================================================
            else if (concimktplaceid == '184') {

                let data = conciarquivojson;

                const pedidosUnicos = new Map();
                data.forEach((pedido, index) => {
                    // Crie uma chave com os campos específicos para identificar pedidos duplicados
                    const chave = `${pedido.pedido}-${pedido.tipo}-${pedido.parc_atual}-${pedido.parc_total}-${pedido.valor}`;
                    // Se a chave já existir, isso significa que encontramos um pedido duplicado
                    if (pedidosUnicos.has(chave)) {
                        const indiceDuplicado = pedidosUnicos.get(chave);
                        // Marque o campo flag como 1 nos dois pedidos duplicados
                        data[indiceDuplicado].flag = 1;
                        pedido.flag = 1;
                    } else {
                        // Se a chave não existir, armazene-a no mapa
                        pedidosUnicos.set(chave, index);
                    }
                });

                // //-------------------------- FIM Adiciona flag a pedidos iguais----------------------


                let pedidos = {};



                // //-------------------------- INICIO trata esttorno----------------------
                let pedidosEstorno = data.filter(item => item.tipo === "Estorno" || item.tipo === "Tarifa" || item.tipo == "Imposto de Renda"); // Filtra apens os itens com tipo "Estorno"

                for (let i of pedidosEstorno) {
                    i.flag = 1
                }
                let pedidosOutrosFiltrEstorno = data.filter(item => item.tipo != "Estorno");
                let pedidosOutrosFiltrTarifa = pedidosOutrosFiltrEstorno.filter(item => item.tipo != "Tarifa");
                let pedidosOutrosFiltrImposto = pedidosOutrosFiltrTarifa.filter(item => item.tipo != "Imposto de Renda");
                // console.log(pedidosOutros);
                // let resultado = pedidosEstorno.concat(pedidosOutros.filter(item => item.pedido !== pedidosEstorno[0].pedido));
                let resultado = pedidosEstorno.concat(pedidosOutrosFiltrImposto.filter(itemOutro => !pedidosEstorno.some(itemEstorno => itemEstorno.pedido === itemOutro.pedido)));


                // //-------------------------- FIM trata esttorno----------------------




                let venda = [];
                let comissao = [];
                let frete = [];
                let taxfrete = [];

                // console.log(resultado)


                // //-------------------------- INICIO trata TAXA----------------------

                // console.log(resultado)
                resultado.forEach(venda => {
                    if (venda.tipo === "Venda") {
                        let valorComissao = 0;
                        let valorFrete = 0;
                        let valorTaxaFixa = 0;

                        resultado.forEach(item => {
                            if (item.pedido === venda.pedido) {
                                if (item.tipo === "Comissão") {
                                    valorComissao += item.valor;
                                } else if (item.tipo === "Frete Madeira Envios") {
                                    valorFrete += item.valor;
                                } else if (item.tipo === "TAXA FIXA Madeira Envios") {
                                    valorTaxaFixa += item.valor;
                                }
                            }
                        });

                        const taxa = valorComissao + valorFrete + valorTaxaFixa;
                        venda.taxa = taxa;
                        venda.valor += taxa;
                    }
                });

                // Filtrar apenas os objetos do tipo "Venda"
                const vendasAtualizadas = data.filter(item => item.tipo === "Venda");

                // console.log(vendasAtualizadas);
                // resolve([]);


                // //-------------------------- FIM trata TAXA----------------------

                // //---------- INICIO concatena todos pedidos com flag(Estorno e duplicados)---------
                let pedidosFlag = resultado.filter(item => item.flag === 1);
                console.log(pedidosFlag)
                dados = vendasAtualizadas.concat(pedidosFlag)
                //---------- FIM concatena todos pedidos com flag(Estorno e duplicados)---------

                for (let p of dados) {
                    if (p.pedido == undefined) {
                        p.pedido = 0;
                    }
                    else if (p.tipo == "Imposto de Renda") {
                        p.pedido = 0;
                    }
                    else if (p.tipo == "Tarifa") {
                        p.pedido = 0;
                    }
                }
            }
            for (let i of dados) {
                i.valor = Math.abs(i.valor);
                i.taxa = Math.abs(i.taxa);
            }



            // removendo flag dos tipo estorno, para os pedidos duplicados 
            // ficarem com flag e acusarem erro para o Eder verificar

            for (let i of dados) {
                if (i.tipo == "Estorno" || i.tipo == "Tarifa" || i.tipo == "Imposto de Renda") {
                    i.flag = null;
                }
            }
            //######################################################################
            let entidade = {};
            if (concimktplaceid == "220" || concimktplaceid == "184") {
                entidade.dados = dados
            } else if (concimktplaceid == "182") {
                entidade.dados = pedidosFiltradosArray
            }


            entidade.concimktplaceid = Number.parseInt(concimktplaceid);
            entidade.conciarquivobuffer = conciarquivobuffer;
            entidade.conciarquivonome = conciarquivonome;
            entidade.login = login;
            entidade.userid = Number.parseInt(userid);
            console.log(entidade)
            Api.postMarketPlacesConciliacao(entidade).then(dados => {


                //atualiza lista de importacoes
                dispatch(marketplacesConciliacaoListImportacoesAction());

                dispatch({ type: MARKETPLACES_CONCI_ISIMPORTING, payload: false });

                //zerando dados
                dispatch({ type: MARKETPLACES_CONCI_IMPORT, payload: [], arquivobuffer: [] })


                resolve(dados);
            })


        }


    });

//método para colocar o arquivo
export const marketplacesConciliacaoImportArquivoAction = (arquivojson, arquivobuffer, arquivonome) => (dispatch) =>
    new Promise(function (resolve, reject) {
        //alert('atualizar arquivo com buffer');
        // alert('marketplacesConciliacaoImportArquivoAction');
        //limpando tela se tiver algum detalhe aberto
        dispatch({ type: MARKETPLACES_CONCI_LIST_REPASSE, concirepasse: [], concirepasselog: [] })

        //console.log('buffer import')
        console.log("@@@@@@@@@@", arquivojson)

        dispatch({
            type: MARKETPLACES_CONCI_IMPORT, payload: arquivojson
            , arquivobuffer: new Buffer(arquivobuffer, 'utf-8')
            , arquivonome: arquivonome

        })
        resolve([])
    });




export const marketplacesHandleChangeAction = (event) => {
    return {
        type: MARKETPLACES_UPDATE_PARAMETROS
        , payload: event.target.value
    }
}

export const marketplacesListAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: MARKETPLACES_ISSEARCHING, payload: true })

        Api.postMarketPlacesPedidos(entidade).then(dados => {
            dispatch({ type: MARKETPLACES_LIST, payload: dados })
            dispatch({ type: MARKETPLACES_ISSEARCHING, payload: false })
            resolve(dados)
        }).catch(error => {
            dispatch({ type: MARKETPLACES_ISSEARCHING, payload: false })
            reject(error);
        })

    });


//só números
//somente números
function justNumbers(text) {
    var numbers = text.replace(/[^0-9]/g, '');
    return parseInt(numbers);
}

/*export const marketplacesHandleChangeParceiroAction = (event) => {

    if (event.target.name == 'codparc') {
        console.log('value:'+event.target.value);
        if(event.target.value.length==0){
            console.log('##entrouzeurou')
        }
        return { type: MARKETPLACES_PARCEIRO_CODPARC, payload: (isNaN(justNumbers(event.target.value)) ? "" : justNumbers(event.target.value)) }
    } else if (event.target.name == 'novacidade') {
        return { type: MARKETPLACES_PARCEIRO_NOVACIDADE, payload: event.target.value }

    }

}*/



export const marketplacesHandleChangeParceiroAction = (event) => (dispatch) =>
    new Promise(function (resolve, reject) {
        if (event.target.name == 'codparc') {
            dispatch({ type: MARKETPLACES_PARCEIRO_DADOS, payload: [] })
            dispatch({ type: MARKETPLACES_PARCEIRO_CODPARC, payload: (isNaN(justNumbers(event.target.value)) ? "" : justNumbers(event.target.value)) })
        } else if (event.target.name == 'novacidade') {
            dispatch({ type: MARKETPLACES_PARCEIRO_NOVACIDADE, payload: event.target.value.toUpperCase() })
        }

    });


export const marketplacesHandleCancelarParceiroAction = (event) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: MARKETPLACES_PARCEIRO_DADOS, payload: [] })
        dispatch({ type: MARKETPLACES_PARCEIRO_CODPARC, payload: "" })
        dispatch({ type: MARKETPLACES_PARCEIRO_NOVACIDADE, payload: "" })
    });




export const marketplacesHandleSearchParceiroAction = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {

        const { parceirocodparc } = getState().MarketPlacesReducer;

        if (parceirocodparc != '') {
            dispatch({ type: MARKETPLACES_PARCEIRO_ISSEARCHING, payload: true })

            Api.getMarketPlacesParceiroCidade(parceirocodparc).then(dados => {
                dispatch({ type: MARKETPLACES_PARCEIRO_DADOS, payload: dados })
                dispatch({ type: MARKETPLACES_PARCEIRO_ISSEARCHING, payload: false })
                resolve(dados)
            }).catch(error => {
                dispatch({ type: MARKETPLACES_PARCEIRO_ISSEARCHING, payload: false })
                reject(error);
            })

        } else {
            dispatch({ type: MARKETPLACES_PARCEIRO_DADOS, payload: [] })
        }


    });


export const marketplacesSaveParceiroAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: MARKETPLACES_PARCEIRO_ISSAVING, payload: true })

        Api.postMarketPlacesParceiroCidade(entidade).then(dados => {
            dispatch({ type: MARKETPLACES_PARCEIRO_NOVACIDADE, payload: "" })
            dispatch({ type: MARKETPLACES_PARCEIRO_ISSAVING, payload: false })
            resolve(dados)
        }).catch(error => {
            dispatch({ type: MARKETPLACES_PARCEIRO_ISSAVING, payload: false })
            reject(error);
        })

    });



export const marketplacesDevoluçõesListAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: MARKETPLACES_DEVOLUCOES_LOADING_LIST, payload: true })

        if (entidade?.codparc == true || entidade?.nunota == true) {
            dispatch({ type: MARKETPLACES_DEVOLUCOES_LOADING_ACTION, payload: true })
        }
        Api.postMarketPlacesDevoluçãoList(entidade).then(dados => {
            dispatch({ type: MARKETPLACES_DEVOLUCOES_LOADING_LIST, payload: false })
            dispatch({ type: MARKETPLACES_DEVOLUCOES_ACTION, payload: dados })
            dispatch({ type: MARKETPLACES_DEVOLUCOES_LOADING_ACTION, payload: false })
            resolve(dados)
        }).catch(error => {
            // dispatch({ type: MARKETPLACES_PARCEIRO_ISSAVING, payload: false })
            reject(error);
        })

    });


// export const marketplacesDevoluçõesCriaDevoluçãoAction = (entidade) => (dispatch) =>
//     new Promise(function (resolve, reject) {
//         dispatch({ type: MARKETPLACES_DEVOLUCOES_CRIA_LOADING_ACTION, payload: true })


//         Api.postMarketPlacesDevoluçãoCria(entidade).then(dados => {
//             dispatch({ type: MARKETPLACES_DEVOLUCOES_CRIA_LOADING_ACTION, payload: false })
//             resolve(dados)
//         }).catch(error => {
//             // dispatch({ type: MARKETPLACES_PARCEIRO_ISSAVING, payload: false })
//             reject(error);
//         })

//     });


const uploadFile = async (files, bucket) => {
    let assistenciaId = files.id;

    // Mapeia os arquivos para promessas de upload

    return new Promise((resolve, reject) => {
        let uploadParams = {}
        // console.log("################", files)
        // console.log("%%%%%%%%%%%%%%%%", bucket)

        if (bucket == 'espacofix') {

            let fileName = `assistencia_espacofix_${Date.now()}_${files.name}`;
            uploadParams = {
                Bucket: 'assistencia-espacofix',
                Key: `${assistenciaId}/${fileName}`,
                Body: files.buffer,
                ContentType: files.type || 'application/octet-stream'
            };

        }
        s3.upload(uploadParams, (err, data) => {
            if (err) {
                reject(`Erro ao fazer upload do arquivo ${fileName}: ${err}`);
            } else {
                // console.log(data);
                resolve({ [`arquivo_laudo`]: data.Location, [`key_laudo`]: data.key });
            }
        });
    });
};


function arrayBufferToBase64(buffer) {
    const binary = [];
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary.push(String.fromCharCode(bytes[i]));
    }
    return btoa(binary.join(''));
}

export const marketplacesDevoluçõesCriaDevoluçãoAction = (dados, files) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: MARKETPLACES_DEVOLUCOES_CRIA_LOADING_ACTION, payload: true })

        const convertFileToBinary = (file) => {
            return new Promise((resolve, reject) => {
                if (!(file instanceof Blob)) {
                    return reject(new Error("O objeto passado não é um Blob ou File válido."));
                }

                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        if (files.length > 0) {


            const binaryFiles = Promise.all(
                files.map(async (file, index) => {
                    const binary = await convertFileToBinary(file); // Garantindo que o file seja um Blob/File
                    const base64Binary = arrayBufferToBase64(binary);

                    return {
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        identidade: `nomearq_${index + 1}`,
                        content: base64Binary
                    };
                })
            );

            binaryFiles
                .then((resolvedBinaryFiles) => {
                    if (!resolvedBinaryFiles || !Array.isArray(resolvedBinaryFiles)) {
                        throw new Error("Erro ao processar arquivos binários");
                    }

                    // Converter o ArrayBuffer para Base64
                    const arrayBase64 = resolvedBinaryFiles.map((file, index) => {
                        const key = `arquivo_${index + 1}`;
                        const nomeArqKey = `nomearq_${index + 1}`;
                        return { [key]: file.content, [nomeArqKey]: file.name };
                    });

                    // Atualizando combinedData com o base64Files e binaryFiles
                    const updatedCombinedData = [dados, arrayBase64, resolvedBinaryFiles];
                    console.log(updatedCombinedData);

                    console.log(updatedCombinedData)
                    Api.postMarketPlacesDevoluçãoCria(updatedCombinedData).then(dados => {
                        dispatch({ type: MARKETPLACES_DEVOLUCOES_CRIA_LOADING_ACTION, payload: false })
                        console.log(dados);
                        resolve(dados)
                    }).catch(error => {
                        dispatch({ type: MARKETPLACES_DEVOLUCOES_CRIA_LOADING_ACTION, payload: false })
                        reject(error);
                    })
                })
                .catch((error) => {
                    console.error("Erro ao processar arquivos:", error);

                    reject(error);
                });
        } else {
            const updatedCombinedData = [dados, [], []];
            Api.postMarketPlacesDevoluçãoCria(updatedCombinedData).then(dados => {
                dispatch({ type: MARKETPLACES_DEVOLUCOES_CRIA_LOADING_ACTION, payload: false })
                console.log(dados);
                resolve(dados)
            }).catch(error => {
                dispatch({ type: MARKETPLACES_DEVOLUCOES_CRIA_LOADING_ACTION, payload: false })
                reject(error);
            })
        }
    });










export const marketplacesDevoluçõesModalInfoAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: MARKETPLACES_DEVOLUCOES_MODAL_INFO_LOADING_ACTION, payload: true })
        Api.getMarketPlacesDevoluçãoModalInfo(entidade).then(dados => {
            dispatch({ type: MARKETPLACES_DEVOLUCOES_MODAL_INFO_LOADING_ACTION, payload: false })
            console.log(dados)
            resolve(dados)
        }).catch(error => {
            dispatch({ type: MARKETPLACES_DEVOLUCOES_MODAL_INFO_LOADING_ACTION, payload: false })
            reject(error);
        })

    });


export const marketplacesDevoluçõesAnaliseAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: MARKETPLACES_DEVOLUCOES_LOADING_ANALISAR, payload: true })


        dispatch({ type: MARKETPLACES_DEVOLUCOES_ANALISAR_INFO_LOADING, payload: true })
        Api.getMarketPlacesDevoluçãoAnalise(entidade).then(dados => {
            dispatch({ type: MARKETPLACES_DEVOLUCOES_ANALISAR_INFO, payload: dados })
            dispatch({ type: MARKETPLACES_DEVOLUCOES_ANALISAR_INFO_LOADING, payload: false })
            dispatch({ type: MARKETPLACES_DEVOLUCOES_LOADING_ANALISAR, payload: false })
            console.log(dados)
            resolve(dados)
        }).catch(error => {
            dispatch({ type: MARKETPLACES_DEVOLUCOES_ANALISAR_INFO_LOADING, payload: false })
            reject(error);
        })

    });



export const marketplacesDevoluçõesEditAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {

        // dispatch({ type: MARKETPLACES_DEVOLUCOES_MODAL_INFO_LOADING_ACTION, payload: true })
        Api.postMarketPlacesDevoluçãoEdit(entidade).then(dados => {
            // dispatch({ type: MARKETPLACES_DEVOLUCOES_ANALISAR_INFO, payload: dados })
            console.log(dados)
            resolve(dados)
        }).catch(error => {
            // dispatch({ type: MARKETPLACES_DEVOLUCOES_MODAL_INFO_LOADING_ACTION, payload: false })
            reject(error);
        })

    });





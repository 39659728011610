import {
    ESPACOTRACK_LIST,
    ESPACOTRACK_LOADING,
    ESPACOTRACK_PARCEIRO,
    ESPACOTRACK_PARCEIRO_LOADING,
    ESPACOTRACK_VENDEDORES,
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    entregas: [],
    vendedores: [],
    isloading: true,
    parceiro: [],
    isloadingParceiro: false,
}

export default (state = INITIAL_STATE, action) => {


    switch (action.type) {

        case ESPACOTRACK_LIST:
            return { ...state, entregas: action.payload }
        case ESPACOTRACK_LOADING:
            return { ...state, isloading: action.payload }
        case ESPACOTRACK_VENDEDORES:
            return { ...state, vendedores: action.payload }
        case ESPACOTRACK_PARCEIRO:
            return { ...state, parceiro: action.payload }
        case ESPACOTRACK_PARCEIRO_LOADING:
            return { ...state, isloadingParceiro: action.payload }

        default:
            return state;
    }
}
import React from 'react';
import { Breadcrumb, Grid, Icon, Menu, Tab, TabPane } from 'semantic-ui-react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { loginRedirectAction } from '../../actions/LoginActions';
import Entregas from './Entregas';

function EspacoTrack(props) {

    const { isloading } = props;

    const StyledTab = styled(Tab)`
&&& {

  .ui.tabular.menu .item{
      font-size:10px !important;
      height:10px !important;
  }
  .ui.menu{
    min-height: 0.0em !important;
  }

 }`

    let panes = [
        {
            menuItem: <Menu.Item key='TAB1'><Icon name='truck' />Entregas</Menu.Item>,
            render: () => (
                <TabPane loading={isloading}>
                    <Entregas />
                </TabPane>
            )
        },
    ];


    return (
        <>
            <Menu stackable size='mini'>
                <Menu.Item>
                    <Breadcrumb size='mini'>
                        <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron' />
                        <Breadcrumb.Section active>Espaco Track</Breadcrumb.Section>
                    </Breadcrumb>
                </Menu.Item>
            </Menu>


            <Grid columns={1} doubling>
                <Grid.Column>
                    <StyledTab panes={panes} />
                </Grid.Column>
            </Grid>
        </>
    );
}

const mapStateToProps = (state) => ({
    isloading: state.EspacoTrackReducer.isLoading
})


export default withRouter(connect(mapStateToProps, {
    loginRedirectAction
}
)(EspacoTrack));

import {
    OUVIDORIA_LIST,
    OUVIDORIA_LIST_LOADING
} from '../actions/ActionsTypes';


const INITIAL_STATE = {
    listOuvidoria: [],
    leadingList: false
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OUVIDORIA_LIST:
            return { ...state, listOuvidoria: action.payload };

        case OUVIDORIA_LIST_LOADING:
            return { ...state, leadingList: action.payload };
        default:
            return state;
    }
};
import * as Api from '../utils/API';
import {
    ESPACOTRACK_LIST,
    ESPACOTRACK_LOADING,
    ESPACOTRACK_PARCEIRO,
    ESPACOTRACK_PARCEIRO_LOADING,
    ESPACOTRACK_VENDEDORES,
} from '../actions/ActionsTypes';

export const EspacoTrackListAction = (userid) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: ESPACOTRACK_LOADING, payload: true });
        Api.getPedidos(userid).then(dados => {
            dispatch({ type: ESPACOTRACK_LIST, payload: dados })
            dispatch({ type: ESPACOTRACK_LOADING, payload: false })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });

export const EspacoTrackVendedoresAction = (userid) => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getVendedores(userid).then(dados => {
            dispatch({ type: ESPACOTRACK_VENDEDORES, payload: dados })
            dispatch({ type: ESPACOTRACK_LOADING, payload: false })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });
export const EspacoTrackParceiroAction = (nunota, userid) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ESPACOTRACK_PARCEIRO_LOADING, payload: true })
        Api.getParceiro(nunota, userid).then(dados => {
            dispatch({ type: ESPACOTRACK_PARCEIRO, payload: dados })
            dispatch({ type: ESPACOTRACK_PARCEIRO_LOADING, payload: false })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });

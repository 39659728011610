import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { despesasDeViagensAdiantamentoFinListAction, despesasDeViagensAdiantamentoFinAprovaAction } from '../../actions/DespesasDeViagensActions';
import {
    Tab, Table, Button, Icon, Popup
} from 'semantic-ui-react';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}
`;


function AdiantamentoFin(props) {

    const { adiantamentoFinList, isLoadingAdiantamentoFinList, userid, listAcomuladoFin } = props; // Reducers
    const { despesasDeViagensAdiantamentoFinListAction, despesasDeViagensAdiantamentoFinAprovaAction } = props; // actions


    const [loadingAprovado, setLoadingAprovado] = useState(true);
    const [loadingNegado, setLoadingNegado] = useState(true);

    useEffect(() => {
        despesasDeViagensAdiantamentoFinListAction().then((data) => {
            setLoadingAprovado(false);
            setLoadingNegado(false);
        })
    }, []);

    const handleClick = (status, id) => {
        let data = {
            status: status == 'Aprovado' ? 1 : 2,
            idviagem: parseInt(id),
            userid: parseInt(userid)
        }
        despesasDeViagensAdiantamentoFinAprovaAction(data).then((data) => {

            despesasDeViagensAdiantamentoFinListAction()
            setLoadingAprovado(false);
            setLoadingNegado(false);
        })

        if (status === 'Aprovado') {
            setLoadingAprovado(true);
        } else if (status === 'Negado') {
            setLoadingNegado(true);
        }
    }

    return (
        <>
            <Tab.Pane loading={isLoadingAdiantamentoFinList}>
                <div>
                    <StyledTable>
                        <StyledTableHeader>
                            <Table.Row style={{ textAlign: 'center' }}>
                                <Table.HeaderCell>OC</Table.HeaderCell>
                                <Table.HeaderCell>Nome do Motorista</Table.HeaderCell>
                                <Table.HeaderCell>Valor</Table.HeaderCell>
                                <Table.HeaderCell>Data da Solicitação</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                            </Table.Row>
                        </StyledTableHeader>
                        {listAcomuladoFin.map((item) => (
                            <StyledTableBody style={{ textAlign: 'center' }} >
                                <Table.Cell>{item.oc}</Table.Cell>
                                <Popup
                                    trigger={<Table.Cell>{item.motorista}</Table.Cell>}
                                    flowing
                                    hoverable
                                    on={["hover", "click"]}
                                >
                                    <p>Motorista: <b>{item.motorista}</b></p>
                                    <table border='1px' style={{ width: '100%', borderCollapse: 'collapse' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}><b>OC</b></th>
                                                <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>NATUREZA</th>
                                                <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>CIDADE</th>
                                                <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>ROTA</th>
                                                <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>VALOR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {adiantamentoFinList.filter(adiantamento => adiantamento.oc === item.oc).map(itempopup => (
                                                <tr key={itempopup.ordemcarga}>
                                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{itempopup.oc}</td>
                                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{itempopup.despesa}</td>
                                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{itempopup.cidade}</td>
                                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{itempopup.rota}</td>
                                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(itempopup.valor)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tr key={item.valorTotal}>
                                            <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }} colSpan={4} align='right'><b>Total:</b></td>
                                            <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valorTotal)}</td>
                                        </tr>
                                    </table>
                                </Popup>
                                <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valorTotal)}</Table.Cell>
                                <Table.Cell> {new Date(item.dtSolicitacao).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                })}</Table.Cell>
                                <Table.Cell>
                                    <Button icon={'gavel'} color={'green'} size="mini" loading={loadingAprovado} onClick={() => handleClick('Aprovado', item.id)}></Button>
                                    <Button icon={'trash alternate outline'} color={'red'} size="mini" loading={loadingNegado} onClick={() => handleClick('Negado', item.id)}></Button>
                                </Table.Cell>
                            </StyledTableBody>
                        ))}
                    </StyledTable>
                </div>
            </Tab.Pane>
        </>
    );
}


const mapStateToProps = (state) => ({
    adiantamentoFinList: state.DespesasDeViagensReducer.adiantamentoFinList,
    isLoadingAdiantamentoFinList: state.DespesasDeViagensReducer.isLoadingAdiantamentoFinList,
    userid: state.LoginReducer.userid,
    listAcomuladoFin: state.DespesasDeViagensReducer.listAcomuladoFin,
});

export default withRouter(connect(mapStateToProps, {
    despesasDeViagensAdiantamentoFinListAction,
    despesasDeViagensAdiantamentoFinAprovaAction
})(AdiantamentoFin));

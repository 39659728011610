import React, { Component, useEffect, useState } from 'react';

import { Button, TextArea, Table, Modal, Form, Dropdown, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import If from '../../utils/If';

import parse from 'html-react-parser';


import { lifecycle } from "react-recompose";
import _ from "lodash";
import { compose, withProps, withStateHandlers } from "react-recompose";
// import {
//     withScriptjs,
//     withGoogleMap,
//     GoogleMap,
//     Marker,
//     InfoWindow,
//     DirectionsRenderer,
//     TrafficLayer,
//     Polyline
// } from "react-google-maps";

import {
    GoogleMap,
    DirectionsRenderer,
    Marker,
    InfoWindow,
    Polyline,
    useJsApiLoader,
    useLoadScript,
    TrafficLayer
} from "@react-google-maps/api";
/* global google */

// import { TrafficLayer } from '@react-google-maps/api';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const MapWithADirectionsRenderer2 = compose(
    withStateHandlers(() => ({
        isOpen: false,
    }), {
        onToggleOpen: ({ isOpen }) => () => ({
            isOpen: !isOpen,
        }),
    }),
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDkYZGyXi8QjtnjZOwfJj-qg5uilkPpSzs&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `500px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    lifecycle({
        componentDidMount() {
            const initializeDirections = () => {
                let steps = [];
                let tracksdraw = [];
                const { coordenadas, tracks, from_lat, from_lng, to_lat, to_lng } = this.props;

                let newsteps = [];
                let limite = 12;
                let conta = 0;
                let total = coordenadas.length;
                let pula = Math.ceil(total / limite);

                if (total <= limite) {
                    newsteps = coordenadas;
                } else {
                    if (total > 1) {
                        newsteps.push(coordenadas[0]);
                    }

                    for (let s of coordenadas) {
                        conta++;
                        if (conta === pula) {
                            conta = 0;
                            newsteps.push(s);
                        }
                        if (newsteps.length === limite - 1) {
                            break;
                        }
                    }

                    if (total > 1) {
                        newsteps.push(coordenadas[total - 1]);
                    }
                }

                for (let s of newsteps) {
                    steps.push(
                        { location: new window.google.maps.LatLng(Number.parseFloat(s.start_location_lat), Number.parseFloat(s.start_location_lng)) },
                        { location: new window.google.maps.LatLng(Number.parseFloat(s.end_location_lat), Number.parseFloat(s.end_location_lng)) }
                    );
                }

                for (let x of tracks) {
                    tracksdraw.push(
                        { lat: Number.parseFloat(x.lat), lng: Number.parseFloat(x.long) }
                    );
                }

                this.setState({ tracksdraw });

                const DirectionsService = new window.google.maps.DirectionsService();

                DirectionsService.route({
                    origin: new window.google.maps.LatLng(Number.parseFloat(from_lat), Number.parseFloat(from_lng)),
                    destination: new window.google.maps.LatLng(Number.parseFloat(to_lat), Number.parseFloat(to_lng)),
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    waypoints: steps,
                }, (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        this.setState({ directions: result });
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                });
            };

            // Verifique se o Google Maps está carregado
            if (window.google && window.google.maps) {
                initializeDirections();
            } else {
                const intervalId = setInterval(() => {
                    if (window.google && window.google.maps) {
                        clearInterval(intervalId);
                        initializeDirections();
                    }
                }, 100);
            }
        },
    })
)(props => {
    const [isOpenModal, setIsOpenModal] = useState();
    const { isOpen, directions, tracks, from_lat, from_lng, to_lat, to_lng, lat, lng, logs } = props;
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDkYZGyXi8QjtnjZOwfJj-qg5uilkPpSzs"
    });

    const handleToggleOpen = () => setIsOpenModal(!isOpen);

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    const processedTracks = tracks.map(track => ({
        lat: parseFloat(track.lat),  // Converte lat para número
        lng: parseFloat(track.long), // Converte long para lng (correto para Google Maps)
    }));



    return (
        <>
            <GoogleMap
                defaultZoom={7}
                defaultCenter={{ lat: -22.88086, lng: -47.12631 }}
                mapContainerStyle={{ height: "500px", width: "100%" }}
            >
                {directions && <DirectionsRenderer directions={directions} />}
                <Marker
                    position={{ lat: Number.parseFloat(lat), lng: Number.parseFloat(lng) }}
                    icon={'img/truck_red.png'}
                    onClick={handleToggleOpen}
                >
                    {isOpen && (
                        <InfoWindow onCloseClick={handleToggleOpen}>
                            <div>
                                lat: {lat}, lng: {lng}
                                <br />O Motorista está aqui
                            </div>
                        </InfoWindow>
                    )}
                </Marker>

                {logs.map((log, index) => (
                    <Marker
                        key={index}
                        position={{
                            lat: Number.parseFloat(log.lat === 0 ? -22.88920463846692 : log.lat),
                            lng: Number.parseFloat(log.lng === 0 ? -47.12596986966311 : log.lng),
                        }}
                        icon={`img/${log.eventid === -1 ? (log.status === 0 ? 'police.png' : 'police-gray.png') : 'helicopter.png'}`}
                        onClick={handleToggleOpen}
                    >
                        {isOpen && (
                            <InfoWindow onCloseClick={handleToggleOpen}>
                                <div>
                                    <b>lat:</b> {log.lat}, <b>lng:</b> {log.lng}, <b>eventid:</b> {log.eventid}
                                    <br /><b>data:</b> {log.creation_time}
                                    <br /><b>vezes:</b> {log.times}
                                    <br /><b>detalhe:</b> {parse(log.detail)}
                                    <br /><b>note:</b> {log.note}
                                </div>
                            </InfoWindow>
                        )}
                    </Marker>
                ))}

                <Polyline
                    path={processedTracks}
                    geodesic={true}
                    options={{
                        strokeColor: "#ff2527",
                        strokeOpacity: 0.75,
                        strokeWeight: 5,
                    }}
                />
                <TrafficLayer />
            </GoogleMap>

            <div style={{ marginBottom: -20, padding: 0 }}>array original: {props.coordenadas.length}</div>
        </>
    );
});


class ModalSteps extends Component {

    state = { open: false };

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    onSaveLog(e, item, oc) {
        //this.props.trackerHandleChangeLogsAction({ target: { name: 'issaving', value: true } }, oc, item.logid);
        if (item.reasonid != null && item.reasonid != 0) {
            this.props.trackerSaveLogsAction(oc, item).then(d => {
                //this.handleOpen(false);
                this.handleOpen(true);
            });
            this.handleOpen(false);
            this.handleOpen(true);
        }

    }
    onChangeLog(e, item, oc) {
        this.props.trackerHandleChangeLogsAction(e, oc, item.logid)
        //this.props.comissoesUpdateTicketAction(item, e.target.value)
    }

    onChangeLogReason = (e, { oc, item, value }) => {
        this.props.trackerHandleChangeLogsAction({ target: { name: 'reasonid', value: value } }, oc, item.logid);
        this.handleOpen(false);
        this.handleOpen(true);
    }


    handleOpen = (value) => {
        this.setState({ open: value });
        this.props.trackersPainelScreenAction(value);
    }


    render() {

        let { data, button, logsreasons, idtemp } = this.props; //idPermissionTemp é temporario
        // console.log(data, 'fon')
        //const [open, setOpen] = useState(false);

        const getMotivo = (reasonid) => {
            if (reasonid == null) {
                reasonid = 0;
            }

            let motivo;
            for (let i of logsreasons) {
                if (i.reasonid == reasonid) {
                    motivo = i.text;
                    break;
                }
            }

            return motivo;

        }

        const getEvento = (eventid) => {


            let evento;

            switch (eventid) {
                case -1:
                    evento = 'Afastamento do Percurso';
                    break;
                case -2:
                    evento = 'Distância Percorrida';
                    break;
                case -3:
                    evento = 'Tempo de Percurso';
                    break;
                case -4:
                    evento = 'Corte de Sinal';
                    break;
                case -5:
                    evento = 'Retorno para Esplane';
                    break;

                default:
                    break;
            }

            return evento;

        }



        return (
            <Modal
                onClose={() => this.handleOpen(false)}
                onOpen={() => this.handleOpen(true)}
                open={this.state.open}
                size='large'
                trigger={button}
                centered={false}

            >
                <Modal.Header>{data.veitag.toLocaleUpperCase()} Dados do Step atual OC: {data.oc}</Modal.Header>
                <Modal.Content image scrolling style={{ flex: 1, overflowY: 'auto' }} >

                    <Modal.Description>
                        <table width={1024} border='1' style={{ borderCollapse: 'collapse' }}>
                            <tr>
                                <td valign='top'>
                                    <div style={{ width: 800 }}>
                                        {
                                            <MapWithADirectionsRenderer2 key="mapcheckin2"
                                                coordenadas={data.steps}// todos steps
                                                lat={data.lat} lng={data.lng}//posicao atual do caminhão
                                                from_lat={data.from_lat} from_lng={data.from_lng} // origem da rota
                                                to_lat={data.to_lat} to_lng={data.to_lng} // destino da rota
                                                logs={data.logs}
                                                tracks={data.tracks}// todos steps

                                            />}
                                    </div>

                                </td>
                                <td valign='top'>
                                    <table border="1" width={224} style={{ borderCollapse: 'collapse' }}>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Idroute:</td>
                                            <td>{data.idroute}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Nunota:</td>
                                            <td>{data.nunota}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Numnota:</td>
                                            <td>{data.numnota}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>APPEntrega:</td>
                                            <td><a href={data.to_address} target='_blank'>Endereço</a></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Pedidofbits:</td>
                                            <td>{data.ad_pedidofbits}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Motorista:</td>
                                            <td>{data.motorista}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Iniciou:</td>
                                            <td>{moment(data.begin_dt).format("DD/MM/YYYY HH:mm")}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Últ. posição:</td>
                                            <td>{moment(data.datelastposition).format("DD/MM/YYYY HH:mm")}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Lat:</td>
                                            <td>{data.lat}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>Lng:</td>
                                            <td>{data.lng}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>cur_distance:</td>
                                            <td>{new Intl.NumberFormat('pt-BR').format(data.cur_distance)}m</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>total_distance:</td>
                                            <td>{new Intl.NumberFormat('pt-BR').format(data.total_distance)}m</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>cur_duration:</td>
                                            <td>{data.cur_duration === null ? '0' : Number.parseInt(data.cur_duration)}min</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold', textAlign: 'right' }}>total_duration:</td>
                                            <td>{Number.parseInt(data.total_duration / 60)}min</td>
                                        </tr>

                                    </table>

                                </td>
                            </tr>
                        </table>
                        <br></br><br></br>

                        <table border="1" width={1033} style={{ borderCollapse: 'collapse' }}>

                            {data.logs.map(item => {
                                if (idtemp == false && item.eventid === -1) { //A logica abaixo de ID é temporário
                                    return null;
                                } else {
                                    return (<>
                                        <tr>
                                            <td colspan={13} bgcolor='#cccccc'>

                                                <hr></hr></td>
                                        </tr>
                                        <tr>
                                            <td align='left'><Label as='a' color='teal' ribbon>
                                                {getEvento(item.eventid)}
                                            </Label>{item.logid}</td>
                                            <td align='right'><b>Lat/Lng:</b> </td>
                                            <td>{item.lat},{item.lng}</td>
                                            <td align='right'><b>Vezes:</b></td>
                                            <td>{item.times}</td>
                                            <td align='right'><b>Idstep</b></td>
                                            <td>{item.idstep}</td>
                                            <td align='right'><b>TrackId</b></td>
                                            <td>{item.trackid}</td>
                                            <td align='right'><b>Tratado por:</b></td>
                                            <td>{(item.status_login != null ? item.status_login + ' em ' + moment(item.status_time).format("DD/MM/YYYY HH:mm") : '---------')}</td>
                                            <td align='right'><b>Criado:</b></td>
                                            <td>{moment(item.creation_time).format("DD/MM/YYYY HH:mm")}</td>
                                        </tr>
                                        <tr>
                                            <td colspan={13}>{parse(item.detail)} Eventid:{item.eventid} - Última vez em: {(item.times_last_time != null ? moment(item.times_last_time).format("DD/MM/YYYY HH:mm") : '')}

                                            </td>
                                        </tr>
                                        <If test={item.status == 0}>
                                            <tr>
                                                <td colspan={12}>
                                                    <Dropdown
                                                        options={logsreasons}
                                                        selection
                                                        placeholder='Selecione o Motivo...'
                                                        name='motivo'
                                                        fluid
                                                        size=''
                                                        value={item.reasonid == null ? '0' : item.reasonid}
                                                        defaultValue={item.reasonid == null ? '0' : item.reasonid}
                                                        item={item}
                                                        oc={data.oc}
                                                        onChange={this.onChangeLogReason}
                                                    />
                                                    <Form.Field control={TextArea} label=''
                                                        placeholder='Digite a justificativa'
                                                        //onChange={this.handleChangeMsgFinanceiro.bind(this)}
                                                        onChange={(e) => this.onChangeLog(e, item, data.oc)}
                                                        maxLength={400}
                                                        style={{ width: "900px" }}
                                                        //value={item.note}
                                                        name='note'
                                                    />

                                                </td>
                                                <td align='center'>
                                                    <Button primary loading={item.issaving} onClick={(e) => this.onSaveLog(e, item, data.oc)}>
                                                        Gravar
                                                    </Button></td>
                                            </tr>
                                        </If>

                                        <If test={item.status == 1}>
                                            <tr>
                                                <td colspan={13}>
                                                    Motivo: {getMotivo(item.reasonid)}<br></br>
                                                    Notas:{item.note}
                                                </td>

                                            </tr>
                                        </If>
                                    </>)
                                }
                            })}

                        </table>


                        <br></br><br></br>

                        <table border="1" width={1033} style={{ borderCollapse: 'collapse' }}>
                            <tr>
                                <th style={{}}>idstep</th>
                                <th style={{}}>distance</th>
                                <th style={{}}>duration</th>
                                <th style={{}}>distance</th>
                                <th style={{}}>duration</th>
                                <th style={{}}>instruction</th>

                                <th style={{}}>start_lat</th>
                                <th style={{}}>start_lng</th>
                                <th style={{}}>end_lat</th>
                                <th style={{}}>end_lng</th>

                            </tr>


                            {data.steps.map(item => (

                                <tr>
                                    <td>{item.idstep}</td>
                                    <td>{item.distance}m</td>
                                    <td>{item.duration}s</td>
                                    <td>{item.distance_text}</td>
                                    <td>{item.duration_text}</td>
                                    <td>{item.instructions}</td>

                                    <td>{item.start_location_lat}</td>
                                    <td>{item.start_location_lng}</td>
                                    <td>{item.end_location_lat}</td>
                                    <td>{item.end_location_lng}</td>

                                </tr>
                            ))}

                        </table>

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions style={{ marginBottom: -100 }} >
                    <Button color='red' onClick={() => {
                        this.handleOpen(false);
                    }}>
                        Fechar
                    </Button>


                </Modal.Actions>
            </Modal>
        )

    }

}

ModalSteps.defaultProps = {
    data: {}
    , button: {}
    , logsreasons: []
};

ModalSteps.propTypes = {
    data: PropTypes.object.isRequired
    , button: PropTypes.object.isRequired
    , trackersPainelScreenAction: PropTypes.func.isRequired
    , logsreasons: PropTypes.array.isRequired
    , trackerHandleChangeLogsAction: PropTypes.func.isRequired
    , trackerSaveLogsAction: PropTypes.func.isRequired
};

export default ModalSteps


import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import moment from 'moment';
import 'moment/locale/pt-br'

import {
  marketplacesHandleChangeAction
  , marketplacesListAction
  , marketplacesHandleChangeParceiroAction
  , marketplacesHandleSearchParceiroAction
  , marketplacesHandleCancelarParceiroAction
  , marketplacesSaveParceiroAction

  , marketplacesConciliacaoHandleChangeAction
  , marketplacesConciliacaoListImportacoesAction
  , marketplacesConciliacaoImportAction
  , marketplacesConciliacaoImportArquivoAction
  , marketplacesConciliacaoProcessImportacoesAction
  , marketplacesConciliacaoListRepasseAction
  , marketplacesConciliacaoImportHandleChangeAction
  , marketplacesConciliacaoRepasseAction
  , marketplacesConciliacaoDeleteAction

} from '../../actions/MarketPlacesActions';

import Conciliacao from './Conciliacao';

import {
  Table, Button, Input, Form, Segment, Message, Tab, Icon, Menu, Breadcrumb, Grid, Checkbox

} from 'semantic-ui-react'

import If from '../../utils/If';

import styled from 'styled-components';
import Devolucao from './Devolucao';





const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



class MarketPlaces extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidMount() {

    //Verificando permissão de funcionalidade do MENU-------------------
    let { menu, history } = this.props;
    if (menu.filter(m => m.menuid === 8 && m.status === 1).length === 0) { history.push(`/Home`) }
    //------------------------------------------------------------------



  }
  handleBusca = () => {

    let pedidos = this.props.parametros
    if (pedidos != '') {
      this.props.marketplacesListAction({ pedidos: pedidos }).then(d => { })
    }

  }


  handleSave = () => {

    if (this.props.parceiroissaving === false && this.props.parceirodados.length > 0) {
      let entidade = {}
      entidade.codparc = this.props.parceirocodparc
      entidade.cidade = this.props.parceironovacidade

      this.props.marketplacesSaveParceiroAction(entidade).then(d => {

        this.props.marketplacesHandleSearchParceiroAction().then(a => { });


      })
    }



  }



  render() {

    let { consulta, parametros, issearching

      , login

      , concimktplaceid
      , conciListMktPlace
      , conciimportacoes
      , conciimportacoesloader
      , conciisimporting
      , conciarquivojson
      , conciarquivobuffer
      , conciarquivonome
      , concirepasse, concirepasselog


      , marketplacesConciliacaoHandleChangeAction
      , marketplacesConciliacaoListImportacoesAction
      , marketplacesConciliacaoImportAction
      , marketplacesConciliacaoImportArquivoAction
      , marketplacesConciliacaoProcessImportacoesAction
      , marketplacesConciliacaoListRepasseAction
      , marketplacesConciliacaoImportHandleChangeAction
      , marketplacesConciliacaoRepasseAction
      , marketplacesConciliacaoDeleteAction

      , userid


    } = this.props;

    let { parceirodados } = this.props;

    let panes = [];

    // rodrigo, michel, nathalya, ederson
    if (userid == 2 || userid == 3 || userid == 10 || userid == 21 || userid == 79 || userid == 86) {

      panes.push({
        menuItem: <Menu.Item key='TABconci'><Icon name='list alternate outline' />Conciliação</Menu.Item>,
        render: () =>
          <Conciliacao
            concimktplaceid={concimktplaceid}
            conciListMktPlace={conciListMktPlace}

            conciimportacoes={conciimportacoes}
            conciimportacoesloader={conciimportacoesloader}
            conciisimporting={conciisimporting}
            conciarquivojson={conciarquivojson}
            conciarquivobuffer={conciarquivobuffer}
            conciarquivonome={conciarquivonome}
            login={login}
            concirepasse={concirepasse}
            concirepasselog={concirepasselog}

            marketplacesConciliacaoHandleChangeAction={marketplacesConciliacaoHandleChangeAction}
            marketplacesConciliacaoListImportacoesAction={marketplacesConciliacaoListImportacoesAction}
            marketplacesConciliacaoImportAction={marketplacesConciliacaoImportAction}
            marketplacesConciliacaoImportArquivoAction={marketplacesConciliacaoImportArquivoAction}
            marketplacesConciliacaoProcessImportacoesAction={marketplacesConciliacaoProcessImportacoesAction}
            marketplacesConciliacaoListRepasseAction={marketplacesConciliacaoListRepasseAction}
            marketplacesConciliacaoImportHandleChangeAction={marketplacesConciliacaoImportHandleChangeAction}
            marketplacesConciliacaoRepasseAction={marketplacesConciliacaoRepasseAction}
            marketplacesConciliacaoDeleteAction={marketplacesConciliacaoDeleteAction}
          />
      })

    }



    const exportToExcel = () => {
      // Formata os dados para o Excel
      const formattedData = consulta.map((item) => ({
        Pedido: item.pedido,
        Codparc: item.codparc,
        Nota: item.numnota,
        Parceiro: item.parceiro,
        'DT Faturamento': item.dtfaturamento,
        Marketplace: item.marketplace,
        'Valor Nota': item.vlrnf,
      }));

      // Cria a planilha e o workbook
      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Pedidos');

      // Exporta o arquivo
      XLSX.writeFile(workbook, 'Pedidos.xlsx');
    };

    panes.push({
      menuItem: <Menu.Item key='TABparceiros'><Icon name='list alternate outline' />Pedidos.</Menu.Item>,
      render: () =>
        <Tab.Pane>
          <Grid columns={2} doubling>
            <Grid.Column>


              <Segment secondary>

                <Form loading={this.props.issearching}>
                  <Form.Group widths='equal'>

                    <Form.TextArea name="parametros" fluid label='Parâmetros' placeholder='Dígite os pedidos marketplace'
                      //onChange={this.handleParceiro.bind(this)}
                      onChange={this.props.marketplacesHandleChangeAction}
                      value={parametros}
                      rows={10}
                    />


                  </Form.Group>
                  <Form.Group widths='eleven'>



                    <div className="field"><label>&nbsp;</label>

                      <Form.Button primary
                        onClick={this.handleBusca}
                        loading={issearching}
                      >Buscar</Form.Button>
                    </div>



                  </Form.Group>



                </Form>
              </Segment>



            </Grid.Column>

            <Grid.Column>
              <div>

                <Message>
                  <Message.Header>Pedidos Marketplaces {'\uD83D\uDE80'}</Message.Header>
                  <p>
                    Nessa tela podemos extrair as informações de todos os pedidos realizados nos Marketplaces do Você Constrói.
                    Será necessário preencher o campo "Parâmetros" com os números de pedidos do marketplace com quebra de linha.
                    <br /><br />
                    Exemplo:<br />
                    1876359315<br />
                    1876359316<br />
                    269153392401<br />
                    269153392402<br />

                  </p>
                </Message>

                <If test={consulta.length > 0}>

                  <Button basic color={'red'} onClick={exportToExcel} size="mini">Exportar para Excel</Button>

                </If>


              </div>
            </Grid.Column>
          </Grid>


          <If test={consulta.length > 0}>
            {`Encontramos ${consulta.length} registro(s)`}<br />
          </If>



          <If test={consulta.length > 0}>



            <StyledTable id="listPedidos" compact celled >
              <StyledTableHeader>
                <Table.Row>

                  <Table.HeaderCell>Pedido</Table.HeaderCell>
                  <Table.HeaderCell>CodParc</Table.HeaderCell>
                  <Table.HeaderCell>Nota</Table.HeaderCell>
                  <Table.HeaderCell>Parceiro</Table.HeaderCell>
                  <Table.HeaderCell>DT Faturamento</Table.HeaderCell>
                  <Table.HeaderCell>Marketplace</Table.HeaderCell>
                  <Table.HeaderCell>Valor Nota</Table.HeaderCell>


                </Table.Row>
              </StyledTableHeader>

              <StyledTableBody>

                {consulta.map(item => (
                  <Table.Row key={"pedido_" + item.numnota}>

                    <Table.Cell>{item.pedido}</Table.Cell>
                    <Table.Cell>{item.codparc}</Table.Cell>
                    <Table.Cell>{item.numnota}</Table.Cell>
                    <Table.Cell>{item.parceiro}</Table.Cell>
                    <Table.Cell>{moment(item.dtfaturamento).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                    <Table.Cell>{item.marketplace}</Table.Cell>
                    <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrnf)}</Table.Cell>
                  </Table.Row>
                ))}

              </StyledTableBody>
            </StyledTable>
          </If>
        </Tab.Pane>
    })




    panes.push({
      menuItem: <Menu.Item key='TABpar'><Icon name='list alternate outline' />Cidade Padrão</Menu.Item>,
      render: () =>
        <Tab.Pane>
          <Grid columns={1} relaxed='very'>
            <Grid.Column verticalAlign='middle'>
              <Form>

                <Input
                  action={{
                    color: 'blue', content: 'Buscar', onClick: this.props.marketplacesHandleSearchParceiroAction
                  }}
                  icon='search'
                  iconPosition='left'
                  placeholder='CodParc #'
                  onChange={this.props.marketplacesHandleChangeParceiroAction}
                  loading={this.props.parceiroissearching}
                  name='codparc'
                  maxLength={10}
                  value={this.props.parceirocodparc}
                />
              </Form>

            </Grid.Column>
            <If test={parceirodados.length > 0}>
              <Grid.Column>
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={2}>Parceiro</Table.Cell>
                      <Table.Cell>{
                        `${parceirodados[0]?.codparc} - ${parceirodados[0]?.parceiro}`
                      }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Cidade</Table.Cell>
                      <Table.Cell>{`${parceirodados[0]?.codcid} - ${parceirodados[0]?.cidade}`}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Nova Cidade</Table.Cell>
                      <Table.Cell>
                        <Form.Input name="novacidade" fluid
                          maxLength={50}
                          onChange={this.props.marketplacesHandleChangeParceiroAction}
                          placeholder="Digitar o nome conforme consta no Mitra. Exemplo: EMBU-GUACU, BOM JESUS DE GOIAS"
                          value={this.props.parceironovacidade}
                        />
                      </Table.Cell>
                    </Table.Row>





                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Button primary
                  loading={this.props.parceiroissaving}
                  onClick={e => this.handleSave()}
                >Salvar</Button>
                <Button secondary
                  onClick={e => this.props.marketplacesHandleCancelarParceiroAction()}
                >Cancelar</Button>
              </Grid.Column>
            </If>

          </Grid>
        </Tab.Pane>
    })
    if (userid == 2 || userid == 3 || userid == 21 || userid == 79 || userid == 86 || userid == 8) {
      panes.push({
        menuItem: <Menu.Item key='TABpar'><Icon name='undo' />Devolução</Menu.Item>,
        render: () => <Devolucao />,
      });
    }
    return (

      <div>

        <Menu stackable size='mini'>
          <Menu.Item>
            <Breadcrumb size='mini'>
              <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
              <Breadcrumb.Divider icon='right chevron' />
              <Breadcrumb.Section active>Marketplaces </Breadcrumb.Section>
            </Breadcrumb>
          </Menu.Item>
        </Menu>


        <Grid columns={1} doubling>
          <Grid.Column>
            <Tab panes={panes} />
          </Grid.Column>
        </Grid>


      </div>

    )

  }

}


const mapStateToProps = state => ({
  menu: state.LoginReducer.menu
  , login: state.LoginReducer.login
  , userid: state.LoginReducer.userid
  , consulta: state.MarketPlacesReducer.consulta
  , issearching: state.MarketPlacesReducer.issearching
  , parametros: state.MarketPlacesReducer.parametros


  , parceirocodparc: state.MarketPlacesReducer.parceirocodparc
  , parceirodados: state.MarketPlacesReducer.parceirodados
  , parceiroissearching: state.MarketPlacesReducer.parceiroissearching
  , parceironovacidade: state.MarketPlacesReducer.parceironovacidade
  , parceiroissaving: state.MarketPlacesReducer.parceiroissaving

  , concimktplaceid: state.MarketPlacesReducer.concimktplaceid
  , conciListMktPlace: state.MarketPlacesReducer.conciListMktPlace
  , conciimportacoes: state.MarketPlacesReducer.conciimportacoes
  , conciimportacoesloader: state.MarketPlacesReducer.conciimportacoesloader
  , conciisimporting: state.MarketPlacesReducer.conciisimporting
  , conciarquivojson: state.MarketPlacesReducer.conciarquivojson
  , conciarquivobuffer: state.MarketPlacesReducer.conciarquivobuffer
  , conciarquivonome: state.MarketPlacesReducer.conciarquivonome
  , concirepasse: state.MarketPlacesReducer.concirepasse
  , concirepasselog: state.MarketPlacesReducer.concirepasselog



})

export default withRouter(connect(mapStateToProps, {
  marketplacesHandleChangeAction
  , marketplacesListAction
  , marketplacesHandleChangeParceiroAction
  , marketplacesHandleSearchParceiroAction
  , marketplacesHandleCancelarParceiroAction
  , marketplacesSaveParceiroAction

  , marketplacesConciliacaoHandleChangeAction
  , marketplacesConciliacaoListImportacoesAction
  , marketplacesConciliacaoImportAction
  , marketplacesConciliacaoImportArquivoAction
  , marketplacesConciliacaoProcessImportacoesAction
  , marketplacesConciliacaoListRepasseAction
  , marketplacesConciliacaoImportHandleChangeAction
  , marketplacesConciliacaoRepasseAction
  , marketplacesConciliacaoDeleteAction

}
)(MarketPlaces));

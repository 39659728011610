import React, { useEffect, useState } from 'react';
import { ouvidoriaListAction, ouvidoriaSaveAction, ouvidoriaUpdateStatusAction } from '../../actions/OuvidoriaActions';
import {
    Tab, Table, Button, Icon, Pagination, Modal, ModalHeader, ModalContent, ModalDescription, ModalActions, Form, TextArea
} from 'semantic-ui-react'
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';



const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}
`;

const CharCounter = styled.div`
  position: absolute;
  bottom: 8px;
  right: 10px;
  font-size: 12px;
  color: #666;
`;






function ListOuvidoria(props) {

    const { ouvidoriaListAction, ouvidoriaSaveAction, ouvidoriaUpdateStatusAction } = props;// actions
    const { listOuvidoria, userid, leadingList } = props;// reducers

    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [open, setOpen] = useState(false);
    const [ouvidoriaClicado, setouvidoriaClicado] = useState([]);
    const [textareaValue, setTextareaValue] = useState('');
    const [loading, setIsLoading] = useState(false);
    const [loadingOnClose, setIsLoadingOnClose] = useState(false);
    const [error, setError] = useState(false);



    useEffect(() => {
        setIsLoading(false);
        setError(false);
        ouvidoriaListAction(activePage).then((dados) => {
            let page_size = 20;
            let totalpages = Math.ceil(dados[0]?.qnt_ouvidoria / page_size);
            setTotalPages(totalpages)
        })
        console.log('chamou aqui')
    }, [])


    const handlePageChange = (e, { activePage }) => {
        setActivePage(activePage); // Atualiza o estado com a nova página ativa
        console.log(`Página ${activePage}`); // Exibe o número da página no console
        // Aqui você pode adicionar a lógica para buscar os dados da nova página, se necessário

        ouvidoriaListAction(activePage).then((data) => {
            let page_size = 20;
            let totalpages = Math.ceil(data[0]?.qnt_ouvidoria / page_size);
            setTotalPages(totalpages)
        });
        console.log('chamou aqui 1')
    };

    const onOpenPopup = (dados) => {
        setOpen(true)
        setouvidoriaClicado(dados)
        if (dados.status == 0 || dados.status == 1) {
            ouvidoriaUpdateStatusAction(dados.protocolo)
        }
        console.log(dados)
    };


    const handleTextareaChange = (e) => {
        setTextareaValue(e.target.value);
    };

    const handleSave = (dados) => {
        setIsLoading(true);
        if (textareaValue == '') {
            setError(true);
            setIsLoading(false);
            return;
        }


        const data = {
            id: dados,
            resposta: textareaValue,
            userid: userid
        }
        setTextareaValue('');
        ouvidoriaSaveAction(data).then(() => {
            ouvidoriaListAction(1).then((dados) => {
                let page_size = 20;
                let totalpages = Math.ceil(dados[0]?.qnt_ouvidoria / page_size);
                setTotalPages(totalpages)
                setIsLoading(false)
                setOpen(false)
            })
        })


    }


    const onClose = () => {
        setIsLoadingOnClose(true)
        ouvidoriaListAction(1).then((dados) => {
            let page_size = 20;
            let totalpages = Math.ceil(dados[0]?.qnt_ouvidoria / page_size);
            setTotalPages(totalpages)
            setIsLoading(false)
            setIsLoadingOnClose(false)
            setOpen(false)
        })
        setError(false);
        setTextareaValue('');
    }
    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                closeOnDimmerClick={false}
            >
                <ModalHeader>Protocolo: {ouvidoriaClicado.protocolo}</ModalHeader>
                <ModalContent>
                    <ModalDescription>
                        <>
                            <div key={ouvidoriaClicado.ouvidoriaid} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div style={{ flex: '1 1 50%', padding: '10px' }}>
                                    <section>
                                        <h3>Informações do Relato</h3>
                                        <span style={{ display: 'block' }}><strong>Id:</strong> {ouvidoriaClicado.ouvidoriaid}</span><br></br>
                                        <span style={{ display: 'block' }}><strong>Quantidade de aberturas: </strong> {ouvidoriaClicado.abertura}</span><br></br>
                                        <span style={{ display: 'block' }}><strong>Data da Última abertura: </strong> {ouvidoriaClicado.dtabertura ? new Date(ouvidoriaClicado.dt_mensagem).toLocaleDateString('pt-BR', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit'
                                        }) : ' '}</span><br></br>

                                        <span style={{ display: 'block' }}><strong>Data de criação: </strong>{ouvidoriaClicado.dt_mensagem ? new Date(ouvidoriaClicado.dt_mensagem).toLocaleDateString('pt-BR', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit'
                                        }) : ' '} </span><br></br>
                                        <span style={{
                                            display: 'block', maxWidth: '800px', whiteSpace: 'pre-wrap',
                                            wordBreak: 'break-all',
                                        }}><strong>Relato:</strong> {ouvidoriaClicado.mensagem}</span><br></br>
                                        {ouvidoriaClicado.status == 2 ?
                                            (<>
                                                <span style={{
                                                    display: 'block', maxWidth: '800px', whiteSpace: 'pre-wrap',
                                                    wordBreak: 'break-all',
                                                }}><strong>Resposta:</strong> {ouvidoriaClicado.resposta}</span><br></br>
                                                <span style={{ display: 'block' }}><strong>Data da Resposta: </strong>{ouvidoriaClicado.dt_resposta ? new Date(ouvidoriaClicado.dt_mensagem).toLocaleDateString('pt-BR', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit'
                                                }) : ' '} </span><br></br>
                                            </>
                                            )
                                            : ('')}
                                    </section>
                                    <hr></hr>
                                    {ouvidoriaClicado.status == 0 || ouvidoriaClicado.status == 1 ?
                                        (<>
                                            <Form>
                                                <label>Resposta:</label>
                                                <Form.Group widths='equal'>

                                                    <Form.Field
                                                        control={TextArea}
                                                        placeholder='Digite...'
                                                        onChange={handleTextareaChange}
                                                        value={textareaValue}
                                                        maxLength={1200}
                                                        error={error}
                                                    // rows='9'
                                                    />

                                                </Form.Group>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    {textareaValue.length}/1200
                                                </div>
                                            </Form>
                                        </>
                                        ) : ('')
                                    }
                                </div>
                            </div>

                        </>

                    </ModalDescription>
                </ModalContent >
                <ModalActions>
                    <Button onClick={() => onClose()} color='red' loading={loadingOnClose}>
                        Fechar
                    </Button>
                    {ouvidoriaClicado.status == 0 || ouvidoriaClicado.status == 1 ?
                        (<Button onClick={() => handleSave(ouvidoriaClicado.ouvidoriaid)} color='green' loading={loading}>
                            Salvar
                        </Button>) : ('')}
                </ModalActions>
            </Modal >

            <Tab.Pane loading={leadingList}>
                <div>
                    <StyledTable>
                        <StyledTableHeader>
                            <Table.Row style={{ textAlign: 'center' }}>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Protocolo</Table.HeaderCell>
                                <Table.HeaderCell>Data da Mensagem</Table.HeaderCell>
                                <Table.HeaderCell>Quantidade de aberturas</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Ação</Table.HeaderCell>
                            </Table.Row>
                        </StyledTableHeader>

                        {listOuvidoria.map((item) => (
                            <StyledTableBody style={{ textAlign: 'center' }} >
                                <Table.Cell>{item.ouvidoriaid}</Table.Cell>
                                <Table.Cell>{item.protocolo}</Table.Cell>
                                <Table.Cell>
                                    {new Date(item.dt_mensagem).toLocaleDateString('pt-BR', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    })}
                                </Table.Cell>
                                <Table.Cell>{item.abertura}</Table.Cell>
                                <Table.Cell>{item.status == 0 ? 'Pendente' : item.status == 1 ? 'Em Analise' : item.status == 2 ? 'Concluído' : ''}</Table.Cell>
                                <Table.Cell>
                                    <Button color='green'
                                        onClick={() => onOpenPopup(item)}
                                    >
                                        <Icon name='search' />
                                        Analisar
                                    </Button>
                                </Table.Cell>
                            </StyledTableBody>

                        ))}
                    </StyledTable>
                    <div style={{ textAlign: 'right', marginTop: '15px' }}>
                        <Pagination
                            boundaryRange={0}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={1}
                            lastItem={totalPages}
                            siblingRange={1}
                            totalPages={totalPages}
                            activePage={activePage} // Vincula o estado da página ativa
                            onPageChange={handlePageChange} // Chama o manipulador ao mudar de página
                        />
                    </div>
                </div>
            </Tab.Pane>
        </>
    );
}


const mapStateToProps = (state) => ({
    listOuvidoria: state.OuvidoriaReducer.listOuvidoria,
    leadingList: state.OuvidoriaReducer.leadingList,
    userid: state.LoginReducer.userid
})
export default withRouter(connect(mapStateToProps, {
    ouvidoriaListAction,
    ouvidoriaSaveAction,
    ouvidoriaUpdateStatusAction
}
)(ListOuvidoria));
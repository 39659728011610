import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import If from '../../utils/If';
import { despesasDeViagensAdiantamentoLogiDadosOCAction, despesasDeViagensAdiantamentoLogCriaAction, despesasDeViagensAdiantamentoLogListAction } from '../../actions/DespesasDeViagensActions';
import { Tab, Input, Button, Dropdown, Icon, Table, Pagination, Popup } from 'semantic-ui-react';
import styled from 'styled-components';




const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}
`;

function AdiantamentoLogi(props) {
    const { despesasDeViagensAdiantamentoLogiDadosOCAction, despesasDeViagensAdiantamentoLogCriaAction, despesasDeViagensAdiantamentoLogListAction } = props; // Actions
    const { adiantamentoLogi, isLoadingAdiantamentoCria, userid, isLoadingAdiantamentoList, adiantamentoList, listAcomuladoLogi } = props; // Reducers

    const [dropdownValue, setDropdownValue] = useState('');
    const [ordemCarga, setOrdemCarga] = useState('');
    const [nomeMotorista, setNomeMotorista] = useState('');
    const [cidadeOptions, setCidadeOptions] = useState([]);
    const [codrota, setCodRota] = useState('');
    const [rota, setRota] = useState('');
    const [valor, setValor] = useState('');
    const [cidade, setCidader] = useState('');
    const [ordensCarga, setOrdensCarga] = useState([]);
    const [errors, setErrors] = useState({});
    const [pernoite, setPernoite] = useState(false);
    const [DropdownValuePernoite, setDropdownValuePernoite] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);



    const options = [
        { text: 'Pernoite', value: 1000 },
        { text: 'Combustível', value: 26 },
        // { text: 'Vale Refeição', value: 30 },
        { text: 'Despesas Gerais com Veiculo', value: 31 },
        // { text: 'Estacionamento', value: 32 },
        // { text: 'Pedágio', value: 33 },
    ];


    const optionsPernoite = [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
        { text: '6', value: 6 },
        { text: '7', value: 7 },
    ];
    const handlePageChange = (e, { activePage }) => {
        setActivePage(activePage); // Atualiza o estado com a nova página ativa
        console.log(`Página ${activePage}`); // Exibe o número da página no console
        // Aqui você pode adicionar a lógica para buscar os dados da nova página, se necessário

        despesasDeViagensAdiantamentoLogListAction(activePage).then((data) => {
            let page_size = 15;
            let totalpages = Math.ceil(data[0]?.qtd_total / page_size);
            console.log(totalpages)
            setTotalPages(totalpages)
        });
    };

    useEffect(() => {
        despesasDeViagensAdiantamentoLogListAction(1).then((data) => {
            let page_size = 15;
            let totalpages = Math.ceil(data[0]?.qtd_total / page_size);
            console.log(data[0]?.qtd_total)
            setTotalPages(totalpages)
        })
    }, []);

    useEffect(() => {
        if (dropdownValue == 1000) {
            const idviagem = ordensCarga[0]?.idviagem
            const codparcmotorista = ordensCarga[0]?.codparcmotorista
            const codparcajudante = ordensCarga[0]?.codparcajudante
            const codparcajudanteduplo = ordensCarga[0]?.codparcajudanteduplo

            if (codparcmotorista != 0 && codparcajudante != 0 && codparcajudanteduplo != 0 && codparcmotorista != null && codparcajudante != null && codparcajudanteduplo != null) {
                setValor(parseInt(DropdownValuePernoite) * 304.86)
            } else if (codparcmotorista != 0 && codparcajudante != 0 && codparcmotorista != null && codparcajudante != null) {
                setValor(parseInt(DropdownValuePernoite) * 203.24)
            } else {
                setValor(parseInt(DropdownValuePernoite) * 101.62)
            }

        }
        // console.log(DropdownValuePernoite)

    }, [optionsPernoite]);


    const buscarOrdemCarga = () => {
        despesasDeViagensAdiantamentoLogiDadosOCAction(ordemCarga).then((dados) => {
            setOrdensCarga(dados); // Atualiza o array de dados
            setNomeMotorista(dados[0]?.motorista || '');
            setCodRota(dados[0]?.codrota)
            // Cria opções do dropdown para as cidades
            const cidades = dados.map((item, index) => ({
                key: `${item.cidade}-${index}`,
                text: item.cidade,
                value: item.cidade,
            }));
            setCidadeOptions(cidades);
        });
    };

    const handleCidadeChange = (e, { value }) => {
        // Busca a rota correspondente com base na cidade selecionada
        const cidadeSelecionada = ordensCarga.find((item) => item.cidade === value);
        if (cidadeSelecionada) {
            setCidader(cidadeSelecionada.cidade);
            setRota(cidadeSelecionada.rota);
        }
    };

    const validateFields = () => {
        const newErrors = {};
        if (!ordemCarga) newErrors.ordemCarga = 'A Ordem de Carga é obrigatória.';
        if (!nomeMotorista) newErrors.nomeMotorista = 'O Nome do Motorista é obrigatório.';
        if (!dropdownValue) newErrors.dropdownValue = 'A Despesa é obrigatória.';
        if (!rota) newErrors.rota = 'A Rota é obrigatória.';
        if (!valor) newErrors.valor = 'O Valor é obrigatório.';
        if (!cidade) newErrors.cidade = 'O Valor é obrigatório.';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Retorna true se não houver erros
    };

    const handleSubmit = () => {
        if (validateFields()) {
            const idviagem = ordensCarga[0]?.idviagem
            const codparcmotorista = ordensCarga[0]?.codparcmotorista
            const codparcajudante = ordensCarga[0]?.codparcajudante
            const codparcajudanteduplo = ordensCarga[0]?.codparcajudanteduplo
            let data = {
                oc: ordemCarga,
                idviagem: idviagem,
                motorista: nomeMotorista,
                natureza: dropdownValue,
                dias: DropdownValuePernoite,
                valor: valor,
                cidade: cidade,
                rota: rota,
                codrota: codrota,
                user: parseInt(userid),
                codparcmotorista: codparcmotorista,
                codparcajudante: codparcajudante,
                codparcajudanteduplo: codparcajudanteduplo
            }

            despesasDeViagensAdiantamentoLogCriaAction(data).then((item) => {
                console.log(item)
                if (item[0].status == 'OK') {
                    despesasDeViagensAdiantamentoLogListAction(1)
                    setOrdemCarga('');
                    setNomeMotorista('');
                    setDropdownValue('');
                    setDropdownValuePernoite('');
                    setValor('');
                    setCidader('');
                    setRota('');
                    setPernoite(false);
                }
            })
        }
    };

    return (
        <>
            <Tab.Pane loading={isLoadingAdiantamentoList}>
                <If test={userid == 2 || userid == 3 || userid == 79 || userid == 26 || userid == 86}>

                    <div>
                        <div>
                            <h4>Criar Adiantamento</h4>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: '1rem',
                                marginTop: '1rem',
                            }}
                        >
                            <div style={{ display: 'flex', gap: '0.5rem' }}>
                                <Input
                                    labelPosition="left"
                                    placeholder="Buscar OC"
                                    value={ordemCarga}
                                    onChange={(e) => setOrdemCarga(e.target.value)}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onBlur={buscarOrdemCarga}
                                    style={{ width: '150px' }}
                                    error={errors.ordemCarga ? true : false}
                                    loading={isLoadingAdiantamentoCria}
                                />
                                <Input
                                    labelPosition="left"
                                    placeholder="Motorista"
                                    value={nomeMotorista}
                                    onChange={(e) => setNomeMotorista(e.target.value)}
                                    style={{ width: '180px' }}
                                    error={errors.nomeMotorista ? true : false}
                                />
                                <Dropdown
                                    selection
                                    options={options}
                                    value={dropdownValue}
                                    onChange={(e, data) => {
                                        setDropdownValue(data.value);
                                        setValor(!data.value === 1000 ? '' : '');
                                        setPernoite(data.value === 1000);
                                    }}
                                    style={{ maxw: '210px' }}
                                    placeholder="Despesa"
                                    error={errors.dropdownValue ? true : false}

                                />
                                {pernoite ? (
                                    <Dropdown
                                        selection
                                        value={DropdownValuePernoite}
                                        options={optionsPernoite}
                                        onChange={(e, data) => setDropdownValuePernoite(data.value)}
                                        style={{ width: '50px' }}
                                        placeholder="Dias"
                                        error={errors.dropdownValue ? true : false}
                                        compact
                                    />
                                ) : ''

                                }
                                <NumericFormat
                                    prefix="R$ "
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    decimalScale={2}
                                    fixedDecimalScale
                                    allowNegative={false}
                                    placeholder="R$ 0,00"
                                    customInput={Input}
                                    value={valor}
                                    onValueChange={(values) => setValor(values.value)}
                                    error={errors.valor ? true : false}
                                    disabled={pernoite}
                                />
                                <Dropdown
                                    selection
                                    options={cidadeOptions}
                                    onChange={handleCidadeChange}
                                    style={{ width: '130px' }}
                                    placeholder="Cidade"
                                    value={cidade}
                                    error={errors.cidade ? true : false}
                                />
                                <Input
                                    labelPosition="left"
                                    placeholder="Rota"
                                    value={rota}
                                    onChange={(e) => setRota(e.target.value)}
                                    style={{ width: '150px' }}
                                    error={errors.rota ? true : false}
                                />
                            </div>
                            <div>
                                <Button
                                    color="blue"
                                    style={{ marginLeft: '15px' }}
                                    onClick={handleSubmit}
                                >
                                    <Icon name="plus" />
                                    Criar
                                </Button>
                            </div>
                        </div>
                    </div>
                </If>
                <div>
                    <StyledTable>
                        <StyledTableHeader>
                            <Table.Row style={{ textAlign: 'center' }}>
                                <Table.HeaderCell>OC</Table.HeaderCell>
                                <Table.HeaderCell>Nome do Motorista</Table.HeaderCell>
                                <Table.HeaderCell>Valor</Table.HeaderCell>
                                <Table.HeaderCell>Data da Solicitação</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                            </Table.Row>
                        </StyledTableHeader>
                        {listAcomuladoLogi.map((item) => (
                            <StyledTableBody style={{ textAlign: 'center' }} key={item.id}>
                                <Table.Cell>{item.oc}</Table.Cell>
                                <Popup
                                    trigger={<Table.Cell>{item.motorista}</Table.Cell>}
                                    flowing
                                    hoverable
                                    on={["hover", "click"]}
                                >
                                    <p>Motorista: <b>{item.motorista}</b></p>
                                    <table border='1px' style={{
                                        width: '100%', borderCollapse: 'collapse'
                                    }}>
                                        <thead>
                                            <tr>
                                                <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}><b>OC</b></th>
                                                <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>NATUREZA</th>
                                                <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>CIDADE</th>
                                                <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>ROTA</th>
                                                <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>VALOR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {adiantamentoList.filter(adiantamento => adiantamento.oc === item.oc).map(itempopup => (
                                                <tr key={itempopup.ordemcarga} style={{
                                                    backgroundColor:
                                                        itempopup.status === 0 ? 'orange' :
                                                            itempopup.status === 1 ? 'green' :
                                                                itempopup.status === 2 ? 'red' : 'transparent',
                                                }}>
                                                    {console.log(item)}
                                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{itempopup.oc}</td>
                                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{itempopup.despesa}</td>
                                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{itempopup.cidade}</td>
                                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{itempopup.rota}</td>
                                                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(itempopup.valor)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tr key={item.valorTotal}>
                                            <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }} colSpan={4} align='right'><b>Total:</b></td>
                                            <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valorTotal)}</td>
                                        </tr>
                                    </table>
                                </Popup>

                                <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valorTotal)}</Table.Cell>
                                <Table.Cell>
                                    {new Date(item.dtSolicitacao).toLocaleDateString('pt-BR', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    })}
                                </Table.Cell>
                                <Table.Cell
                                    style={{
                                        color: item.status === 1 ? 'green' : item.status === 2 ? 'red' : item.status === 0 ? 'orange' : 'black',
                                        fontWeight: '700'
                                    }}
                                >
                                    {item.status === 0
                                        ? 'Pendente'
                                        : item.status === 1
                                            ? 'Aprovado'
                                            : item.status === 2
                                                ? 'Negado'
                                                : ''}
                                </Table.Cell>
                            </StyledTableBody>
                        ))}
                    </StyledTable>

                </div>
            </Tab.Pane>
            <div style={{ textAlign: 'right', marginTop: '15px' }}>
                <Pagination
                    boundaryRange={0}
                    defaultActivePage={1}
                    ellipsisItem={null}
                    firstItem={1}
                    lastItem={totalPages}
                    siblingRange={1}
                    totalPages={totalPages}
                    activePage={activePage} // Vincula o estado da página ativa
                    onPageChange={handlePageChange} // Chama o manipulador ao mudar de página
                />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    adiantamentoLogi: state.DespesasDeViagensReducer.adiantamentoLogi,
    isLoadingAdiantamentoCria: state.DespesasDeViagensReducer.isLoadingAdiantamentoCria,
    userid: state.LoginReducer.userid,
    isLoadingAdiantamentoList: state.DespesasDeViagensReducer.isLoadingAdiantamentoList,
    adiantamentoList: state.DespesasDeViagensReducer.adiantamentoList,
    listAcomuladoLogi: state.DespesasDeViagensReducer.listAcomuladoLogi,
});

export default withRouter(
    connect(mapStateToProps, {
        despesasDeViagensAdiantamentoLogiDadosOCAction,
        despesasDeViagensAdiantamentoLogCriaAction,
        despesasDeViagensAdiantamentoLogListAction
    })(AdiantamentoLogi)
);

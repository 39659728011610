import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
    Tab, Table, Grid, Segment, Header, Button,
    Icon,
    Dropdown, Input, Pagination
} from 'semantic-ui-react';
import styled, { css, keyframes } from 'styled-components';
import { assistenciaSalvaPagination, assistenciaListFiltroAction, assistenciaListAction, assistenciaModalAction, assistenciaModalClearAction, assistenciaStatusAction, assistenciaCardListAction } from '../../actions/AssistenciaActions';
import AssistenciaModal from './AssistenciaModal'; // Importe o novo componente

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;!important;
    margin-bottom: 0px;!important;
    padding: 0px 0px;!important;
  }
`;

const StyledTableHeader = styled(Table.Header)`
  &&& th {
    padding: 0px;!important;
    margin: 0px 0px 0px 0px;!important;
  }
`;

const StyledTableBody = styled(Table.Body)`
  &&& td {
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
  }
`;

const pulseRed = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
  }
  70% {
    box-shadow: 0 0 0 30px rgba(220, 53, 69, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
  }
`;

const pulseStyle = css`
  animation: ${pulseRed} 1.5s infinite;
  background-color: #f8d7da;
  border-radius: 20px;
`;

// Componente StyledSegment com pulse condicional
const StyledSegment = styled.div`
  width: 100%;
  heigth: 100%;
  ${({ pulse }) => pulse && pulseStyle};
`;

const DateCell = styled.div`
  text-align: center;
  ${({ pulse }) => pulse && pulseStyle};
`;

const getStatusColor = (status) => {
    switch (status) {
        case 0:
            return '#6c757d';
        case 1:
            return '#20c997';
        case 2:
            return '#fd7e14';
        case 3:
            return '#383845';
        case 4:
            return '#28a745';
        case 5:
            return '#dc3545';
        default:
            return '#6c757d'; // Caso o status não seja 1, 2, 3 ou 4
    }
};

const StatusLabel = styled.span`
  background-color: ${props => getStatusColor(props.status)};
  color: white;
  padding: 0.2em 0.5em;
  border-radius: 5px;
  font-weight: bold;
  white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`;

function ListAssistencia(props) {
    const { assistenciaSalvaPagination, assistenciaListFiltroAction, assistenciaListAction, assistenciaModalAction, assistenciaModalClearAction, assistenciaStatusAction, assistenciaCardListAction } = props; // actions
    const { assistencias, isloading, modal, isLoadingModal, isFiltro, isFiltroSts, isloadingGrid, userid, assistenciascard, isLoadginSearch, totalPag, atualPag, } = props; // reducers
    const [loadingButtons, setLoadingButtons] = useState({});
    const [dropdownValue, setDropdownValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [statusatual, setStatusAtual] = useState(6);
    const [error, setError] = useState(false);
    const [totalPage, setTotalPage] = useState(0)
    const [initialCounts, setInitialCounts] = useState({
        aguardandoAnalise: 0,
        emAnalise: 0,
        aguardandoCliente: 0,
        aguardandoTecnico: 0,
        aprovada: 0,
        reprovada: 0,
        geral: 0
    });

    // Função para recalcular as contagens


    const truncateName = (name, wordLimit) => {
        const words = name?.split(' ');

        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return name;
    };

    const handleAnalyzeClick = (assistencia_id, status) => {
        setLoadingButtons(prevState => ({
            ...prevState,
            [assistencia_id]: true
        }));
        //JAO
        if (status == 0) {
            let data = {
                assistencia_id: assistencia_id,
                status: 1,
                userid: parseInt(userid)
            }
            assistenciaStatusAction(data).then((dados) => {
                if (dados == 'OK') {
                    assistenciaModalAction(assistencia_id).then((dados) => {
                        if (dados === 'OK') {
                            setModalOpen(true);
                        }
                        setLoadingButtons(prevState => ({
                            ...prevState,
                            [assistencia_id]: false
                        }));
                    }).catch(() => {
                        setLoadingButtons(prevState => ({
                            ...prevState,
                            [assistencia_id]: false
                        }));
                    });
                }
            })
        } else {
            assistenciaModalAction(assistencia_id).then((dados) => {
                if (dados === 'OK') {
                    setModalOpen(true);
                }
                setLoadingButtons(prevState => ({
                    ...prevState,
                    [assistencia_id]: false
                }));
            }).catch(() => {
                setLoadingButtons(prevState => ({
                    ...prevState,
                    [assistencia_id]: false
                }));
            });
        }


    };

    const handleGridClick = (status) => {
        setStatusAtual(status)
        if (status != 6) {
            console.log('caiu aqui')
            const data = { status: status, page: 1 }
            assistenciaSalvaPagination(1, totalPag);
            assistenciaListFiltroAction(data).then((dados) => {
                let qnt_asst = dados[0].qnt_asst; // Supondo que isso venha da resposta
                let page_size = 15; // Você pode ajustar isso conforme necessário
                let totalpages = Math.ceil(qnt_asst / page_size);
                setTotalPage(totalpages);
            });
            assistenciaCardListAction()
        } else {
            assistenciaSalvaPagination(1, totalPag);
            assistenciaListAction(atualPag).then((dados) => {
                let qnt_asst = dados[0].qnt_asst; // Supondo que isso venha da resposta
                let page_size = 15; // Você pode ajustar isso conforme necessário
                let totalpages = Math.ceil(qnt_asst / page_size);
                setTotalPage(totalpages);
                assistenciaCardListAction().then(d => {

                })

            });
        }
    };

    const handleModalClose = () => {
        assistenciaModalClearAction();
        if (isFiltro) {
            assistenciaListAction(atualPag).then((dados) => {
                console.log(dados)
                assistenciaCardListAction().then(() => {
                    // recalculateCounts(dados);
                })
                assistenciaCardListAction().then((dados) => {
                    recalculateCounts(dados)
                })
            });
        } else {
            assistenciaCardListAction().then((dados) => {
                // recalculateCounts(dados);
                const data = { status: statusatual, page: atualPag }
                assistenciaListFiltroAction(data);
            })
            assistenciaCardListAction().then((dados) => {
                recalculateCounts(dados)
            })

        }
        setModalOpen(false);
    };


    const recalculateCounts = (dados) => {
        console.log('recalculate')

        console.log('1 opção', dados?.length)
        console.log('2 opção', assistenciascard)

        const counts = {
            aguardandoAnalise: 0,
            emAnalise: 0,
            aguardandoCliente: 0,
            aguardandoTecnico: 0,
            aprovada: 0,
            reprovada: 0,
            geral: 0,
        };

        if (dados?.length > 0) {
            dados.forEach(item => {
                switch (item.status) {
                    case 0:
                        counts.aguardandoAnalise++;
                        break;
                    case 1:
                        counts.emAnalise++;
                        break;
                    case 2:
                        counts.aguardandoCliente++;
                        break;
                    case 3:
                        counts.aguardandoTecnico++;
                        break;
                    case 4:
                        counts.aprovada++;
                        break;
                    case 5:
                        counts.reprovada++;
                        break;
                    case 6:
                        counts.geral++;
                        break;
                    default:
                        break;
                }
            });
        } else {
            assistenciascard.forEach(item => {
                switch (item.status) {
                    case 0:
                        counts.aguardandoAnalise++;
                        break;
                    case 1:
                        counts.emAnalise++;
                        break;
                    case 2:
                        counts.aguardandoCliente++;
                        break;
                    case 3:
                        counts.aguardandoTecnico++;
                        break;
                    case 4:
                        counts.aprovada++;
                        break;
                    case 5:
                        counts.reprovada++;
                        break;
                    case 6:
                        counts.geral++;
                        break;
                    default:
                        break;
                }
            });
            console.log(counts)
        }


        counts.geral = counts.aguardandoAnalise + counts.emAnalise + counts.aguardandoCliente + counts.aprovada + counts.reprovada + counts.aguardandoTecnico;
        // console.log()

        setInitialCounts(counts);
    };

    // Calculando as quantidades iniciais e salvando no estado local
    useEffect(() => {
        if (isFiltroSts == null) {
            assistenciaListAction(atualPag).then((dados) => {
                let qnt_asst = dados[0].qnt_asst; // Supondo que isso venha da resposta
                let page_size = 15; // Você pode ajustar isso conforme necessário
                let totalpages = Math.ceil(qnt_asst / page_size);
                setTotalPage(totalpages);


            });
            assistenciaCardListAction().then((dados) => {
                recalculateCounts(dados)
            })
        }
    }, [atualPag]);


    const dropdownOptions = [
        { key: 'codparc', text: 'Codparc', value: 1 },
        { key: 'protocolo', text: 'Protocolo', value: 2 }
    ];

    // Função que será chamada ao clicar no botão buscar codparc/protocolo
    const handleSearch = () => {
        if (!dropdownValue || inputValue.trim() === '') {
            setError(true);  // Exibe erro se não estiver preenchido
        } else {
            setError(false); // Reseta o erro se estiver tudo preenchido
            const data = {
                codparc: dropdownValue == 1 ? inputValue : null,
                protocolo: dropdownValue == 2 ? inputValue : null,
                page: 1
            }
            assistenciaListFiltroAction(data);
            console.log('Dropdown selecionado:', dropdownValue);
            console.log('Texto do input:', inputValue);
            console.log('DATA', data);

        }

    };

    const handlePageChange = (event, { activePage }) => {
        assistenciaSalvaPagination(activePage, totalPag);

        if (isFiltro !== null) {
            const data = { status: isFiltroSts, page: activePage }
            console.log(data)
            assistenciaListFiltroAction(data).then((dados) => {
                let qnt_asst = dados[0].qnt_asst; // Supondo que isso venha da resposta
                let page_size = 15; // Você pode ajustar isso conforme necessário
                let totalpages = Math.ceil(qnt_asst / page_size);
                setTotalPage(totalpages);
            });
        } else {
            assistenciaListAction(activePage).then((dados) => {
                let qnt_asst = dados[0].qnt_asst; // Supondo que isso venha da resposta
                let page_size = 15; // Você pode ajustar isso conforme necessário
                let totalpages = Math.ceil(qnt_asst / page_size);
                setTotalPage(totalpages);
            });
            window.scrollTo(0, 0);
        }

    };


    return (
        <>
            <Tab.Pane loading={isloadingGrid}>
                <h1>Assistência</h1>
                <Grid columns={7} divided>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment textAlign="center" style={{
                                cursor: 'pointer',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%'
                            }} onClick={() => handleGridClick(6)}>
                                <Header as="h1" style={{
                                    color: '#66abf9',
                                    fontSize: '5em',
                                    fontFamily: "'Arial Black', Gadget, sans-serif",
                                }}>
                                    {initialCounts.geral}
                                </Header>
                                <p>Geral</p>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment textAlign="center" style={{
                                cursor: 'pointer',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%'
                            }} onClick={() => handleGridClick(0)}>
                                <Header as="h1" style={{
                                    color: '#6c757d',
                                    fontSize: '5em',
                                    fontFamily: "'Arial Black', Gadget, sans-serif"
                                }}>
                                    {initialCounts.aguardandoAnalise}
                                </Header>
                                <p>Aguardando Análise</p>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment textAlign="center" style={{
                                cursor: 'pointer',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%'
                            }} onClick={() => handleGridClick(1)}>
                                <Header as="h1" style={{
                                    color: '#20c997',
                                    fontSize: '5em',
                                    fontFamily: "'Arial Black', Gadget, sans-serif"
                                }}>
                                    {initialCounts.emAnalise}
                                </Header>
                                <p>Em análise</p>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment textAlign="center" style={{
                                cursor: 'pointer',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%'
                            }} onClick={() => handleGridClick(2)}>
                                <Header as="h1" style={{
                                    color: '#fd7e14',
                                    fontSize: '5em',
                                    fontFamily: "'Arial Black', Gadget, sans-serif"
                                }}>
                                    {initialCounts.aguardandoCliente}
                                </Header>
                                <p>Aguardando Cliente</p>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment textAlign="center" style={{
                                cursor: 'pointer',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%'
                            }} onClick={() => handleGridClick(3)}>

                                <StyledSegment
                                    pulse={initialCounts.aguardandoTecnico > 0} >
                                    <Header as="h1" style={{
                                        color: '#383845',
                                        fontSize: '5em',
                                        fontFamily: "'Arial Black', Gadget, sans-serif"
                                    }}>
                                        {initialCounts.aguardandoTecnico}
                                    </Header>
                                    <p>Aguardando Técnico</p>
                                </StyledSegment>
                            </Segment>

                        </Grid.Column>
                        <Grid.Column>
                            <Segment textAlign="center" style={{
                                cursor: 'pointer',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%'
                            }} onClick={() => handleGridClick(4)}>
                                <Header as="h1" style={{
                                    color: '#28a745',
                                    fontSize: '5em',
                                    fontFamily: "'Arial Black', Gadget, sans-serif"
                                }}>
                                    {initialCounts.aprovada}
                                </Header>
                                <p>Procedente</p>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment textAlign="center" style={{
                                cursor: 'pointer',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%'
                            }} onClick={() => handleGridClick(5)}>
                                <Header as="h1" style={{
                                    color: '#dc3545',
                                    fontSize: '5em',
                                    fontFamily: "'Arial Black', Gadget, sans-serif"
                                }}>
                                    {initialCounts.reprovada}
                                </Header>
                                <p>Improcedente</p>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <hr></hr>
                <h4>Buscar Assistência.</h4>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <Dropdown
                            placeholder='Selecione uma opção'
                            selection
                            options={dropdownOptions}
                            onChange={(e, { value }) => setDropdownValue(value)}
                            error={error && !dropdownValue} // Exibe erro se o dropdown não estiver selecionado
                        />
                        <Input
                            placeholder=''
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            error={error && inputValue.trim() === ''} // Exibe erro se o input estiver vazio

                        />
                    </div>
                    <Button onClick={handleSearch} style={{ flexShrink: 0 }} loading={isLoadginSearch}>
                        Buscar
                    </Button>
                </div>
                <Tab.Pane loading={isloading}>
                    <StyledTable celled id="listPendentes" compact='very' size='small'>
                        <StyledTableHeader>
                            <Table.Row>
                                <Table.HeaderCell width="2">Protocolo</Table.HeaderCell>
                                <Table.HeaderCell width="3">Nome/Codparc</Table.HeaderCell>
                                <Table.HeaderCell width="1">Nº Nota</Table.HeaderCell>
                                <Table.HeaderCell width="4">Produto/Fabricante</Table.HeaderCell>
                                <Table.HeaderCell width="2">Dt Abertura</Table.HeaderCell>
                                <Table.HeaderCell width="1">Prazo</Table.HeaderCell>
                                <Table.HeaderCell width="2">Status</Table.HeaderCell>
                                <Table.HeaderCell width="1">Ação</Table.HeaderCell>
                            </Table.Row>
                        </StyledTableHeader>

                        {assistencias.map((item) => {
                            return (
                                <StyledTableBody key={item.protocolo}>
                                    <Table.Row>
                                        <Table.Cell style={{ textAlign: 'center' }}>{item.protocolo}</Table.Cell>
                                        <Table.Cell>{truncateName(item.nomeparc, 3)}({item.codparc})</Table.Cell> {/* Truncando o nome do cliente */}
                                        <Table.Cell style={{ textAlign: 'center' }}>{item.nunota}</Table.Cell>
                                        <Table.Cell>{truncateName(item.produto, 3)}({item.fabricante})</Table.Cell> {/* Truncando o nome do produto */}
                                        <Table.Cell style={{ textAlign: 'center' }}>
                                            {new Date(item.dtcriacao).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}
                                        </Table.Cell>
                                        <Table.Cell style={{ textAlign: 'center' }}>
                                            {new Date(item.prazo).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                            })}
                                        </Table.Cell>
                                        <Table.Cell style={{ textAlign: 'center' }}>
                                            <DateCell
                                                pulse={item.status === 0 && new Date() - new Date(item.dtcriacao) > 24 * 60 * 60 * 1000}
                                            >
                                                <StatusLabel
                                                    status={item.status}
                                                    style={{
                                                        color: (item.status === 0 && new Date() - new Date(item.dtcriacao) > 24 * 60 * 60 * 1000)
                                                            ? 'red'  // A cor desejada quando o status é 0 e dtcriação > 24h
                                                            : 'white' // A cor padrão para outros casos
                                                    }}
                                                >
                                                    {item.status === 0 ? 'Aguardando Análise' :
                                                        item.status === 1 ? 'Em análise' :
                                                            item.status === 2 ? 'Aguardando Cliente' :
                                                                item.status === 3 ? 'Aguardando Técnico' :
                                                                    item.status === 4 ? 'Procedente' :
                                                                        item.status === 5 ? 'Improcedente' : ''}
                                                </StatusLabel>
                                            </DateCell>
                                        </Table.Cell>
                                        <Table.Cell >
                                            <Button
                                                basic
                                                color='green'
                                                size='mini'
                                                onClick={() => handleAnalyzeClick(item.assistencia_id, item.status)}
                                                loading={loadingButtons[item.assistencia_id] || false}
                                                icon
                                                labelPosition='left'

                                            >
                                                <Icon name='search' />
                                                Analise
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>

                                </StyledTableBody>
                            );
                        })}

                    </StyledTable>
                    <div style={{ textAlign: 'right', marginTop: '10px' }}>
                        <Pagination
                            boundaryRange={0}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={1}
                            siblingRange={1}
                            activePage={atualPag}
                            totalPages={totalPage}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </Tab.Pane>


            </Tab.Pane>
            <AssistenciaModal
                modalOpen={modalOpen}
                modal={modal}
                handleModalClose={handleModalClose}
            />
        </>
    );
}
const mapStateToProps = (state) => ({
    assistencias: state.AssistenciaReducer.assistencias,
    assistenciascard: state.AssistenciaReducer.assistenciascard,
    isloading: state.AssistenciaReducer.isloading,
    modal: state.AssistenciaReducer.assistenciamodal,
    isLoadingModal: state.AssistenciaReducer.isloadingModal,
    isFiltro: state.AssistenciaReducer.isFiltro,
    isFiltroSts: state.AssistenciaReducer.isFiltroSts,
    isloadingGrid: state.AssistenciaReducer.isloadingGrid,
    userid: state.LoginReducer.userid,
    isLoadginSearch: state.AssistenciaReducer.isLoadginSearch,
    atualPag: state.AssistenciaReducer.atualPag,
    totalPag: state.AssistenciaReducertotalPag
})


export default withRouter(connect(mapStateToProps, {
    assistenciaListFiltroAction,
    assistenciaListAction,
    assistenciaModalAction,
    assistenciaModalClearAction,
    assistenciaStatusAction,
    assistenciaCardListAction,
    assistenciaSalvaPagination
}
)(ListAssistencia));


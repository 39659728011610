import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import sortBy from 'sort-by';
import parse from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/pt-br'

import {
    Table, Popup, Checkbox, Form, Segment, Message, Image, Header
} from 'semantic-ui-react'

import If from '../../utils/If';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import ReactToPrint from "react-to-print";

import { CopyToClipboard } from 'react-copy-to-clipboard';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


class Consulta extends Component {


    state = { errordtinicial: 0, errordtfinal: 0, copied: '' }

    constructor(props) {
        super(props);
    }



    componentWillReceiveProps() {

    }

    componentDidMount() {

    }



    handleBusca = () => {

        this.setState({ copied: '' })
        if (!this.props.isSearching) {
            if (
                (this.props.buscaEntity.sku != "" || this.props.buscaEntity.sku != 0)
                &&
                (this.props.buscaEntity.qtde != "" || this.props.buscaEntity.qtde != 0)
            ) {
                this.props.orcamentosListSearchAction(this.props.buscaEntity).then(dados => {

                })
            }
        }
    }



    updateCHK = (e, { checked }) => {
        this.props.orcamentosUpdateAction({ target: { 'name': 'ml', 'value': checked } });
    }






    render() {

        let { datalist, buscaEntity } = this.props








        return (
            <div>
                <Segment secondary>

                    <Form loading={this.props.isSearching}>
                        <Form.Group widths='equal'>

                            <Form.Input name="sku" fluid label='Sku' placeholder='Sku do produto'
                                onChange={this.props.orcamentosUpdateAction}

                            />


                            <Form.Input name="qtde" fluid label='Qtde' placeholder='Qtde do produto'
                                onChange={this.props.orcamentosUpdateAction}

                            />

                            <div className="field"><label></label>
                                <br></br>
                                <Form.Checkbox label="Mercado Livre" name="ml"
                                    checked={buscaEntity.ml}
                                    onChange={this.updateCHK}

                                />
                            </div>

                            <div className="field"><label>&nbsp;</label>
                                <Form.Button primary
                                    onClick={this.handleBusca}
                                >Gerar</Form.Button>
                            </div>

                        </Form.Group>



                    </Form>
                </Segment>

                {datalist.map(item => (




                    <Segment secondary>
                        <Popup
                            trigger={<Header as='h3'>Detalhes do Orçamento:</Header>}
                            content={`
                                Após gerar o orçamento, os detalhes do produto aparecerão abaixo
                                `}
                            wide='very'
                        />

                        <Form key='Informacoesdoproduto'>
                            <CopyToClipboard text={parse(item.descricao)}
                                onCopy={() => this.setState({ copied: 'Copiado' })}>
                                <button>Copiar</button>
                            </CopyToClipboard>{this.state.copied}
                            <Form.Group widths='equal'>
                                <Form.TextArea
                                    name="infotextoproduto" placeholder=''
                                    rows={10}
                                    value={parse(item.descricao)}
                                />
                            </Form.Group>
                        </Form>

                    </Segment>
                ))}
            </div>

        )
    }

}

Consulta.defaultProps = {
    datalist: []
    , buscaEntity: {}
    , isSearching: false
};

Consulta.propTypes = {
    datalist: PropTypes.array.isRequired
    , buscaEntity: PropTypes.object.isRequired
    , isSearching: PropTypes.bool.isRequired
    , orcamentosUpdateAction: PropTypes.func.isRequired
    , orcamentosListSearchAction: PropTypes.func.isRequired
};

export default Consulta


//

import {
    PENTEFINO_LIST
    , PENTEFINO_LIST_LOADER
} from '../actions/ActionsTypes'


import * as Api from '../utils/API';

export const pentefinoListAction = (page) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: PENTEFINO_LIST_LOADER, payload: true })
        Api.getPenteFino().then(dados => {
            dispatch({ type: PENTEFINO_LIST_LOADER, payload: false })
            dispatch({ type: PENTEFINO_LIST, payload: dados })
            resolve(dados)
        }).catch(error => {
            dispatch({ type: PENTEFINO_LIST_LOADER, payload: false })
            reject(error);
        })

    });




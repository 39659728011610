import React, { Component } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment';
import {
    Grid, Form, Icon, Label
    , Modal, Button, Header, Table, Message, Card
    ,
} from 'semantic-ui-react'

import If from '../../utils/If';

import styled from 'styled-components';

import ReactToPrint from "react-to-print";
import { despesasDeViagensSaidasCancelAction } from '../../actions/DespesasDeViagensActions';


// import PrintProvider, { Print, NoPrint } from 'react-easy-print';





const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
    max-width: 500px;
    
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 4px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


class ModalRecibos extends Component {


    state = {}

    constructor(props) {
        super(props);
    }


    onValidSubmit = (formData) => {
        //this.form.reset()
        //this.props.handleSavePesquisa()
    }


    componentWillReceiveProps() {




    }

    componentDidMount() {




    }

    calculaTotal(despesas) {

        let valor = 0
        for (let i of despesas) {
            valor += i.valor
        }
        return valor
    }


    render() {


        let totalmotorista = 0
        let totalajudante = 0
        let totalajudanteduplo = 0

        let { datalist } = this.props

        let dados = []
        let todosdias = []

        let cargas = []

        for (let i of datalist) {
            todosdias = []

            for (let f of i.despesas) {
                if (f.codparcmotorista != 0) {
                    f.tipodado = 'motorista'
                } else if (f.codparcajudante != 0) {
                    f.tipodado = 'ajudante'
                } else if (f.codparcaajudanteduplo != 0) {
                    f.tipodado = 'ajudanteduplo'
                }
                todosdias.push({ dia: f.dtviagemfinanceiro, despesas: [] })
            }

            todosdias = [...new Set(todosdias.map(o => JSON.stringify(o)))]
            todosdias = todosdias.map(o => JSON.parse(o))

            let arrmotoristadespesas = []
            let arrajudantedespesas = []
            let arrajudanteduplodespesas = []

            for (let d of todosdias) {
                arrmotoristadespesas.push({ dia: d.dia, despesas: [] })
                arrajudantedespesas.push({ dia: d.dia, despesas: [] })
                arrajudanteduplodespesas.push({ dia: d.dia, despesas: [] })
            }

            let objeto = {
                ordemcarga: i.ordemcarga
                , motorista: i.motorista
                , ajudante: i.ajudante
                , ajudanteduplo: i.ajudanteduplo
                , motoristadespesas: arrmotoristadespesas
                , ajudantedespesas: arrajudantedespesas
                , ajudanteduplodespesas: arrajudanteduplodespesas
            }


            for (let mo of objeto.motoristadespesas) {
                let arrdesp = [...i.despesas.filter(t => t.tipodado === 'motorista' && t.dtviagemfinanceiro === mo.dia)]
                if (arrdesp.length > 0) {
                    mo.despesas.push(arrdesp)
                }
            }

            for (let aj of objeto.ajudantedespesas) {
                let arrdesp = [...i.despesas.filter(t => t.tipodado === 'ajudante' && t.dtviagemfinanceiro === aj.dia)]
                if (arrdesp.length > 0) {
                    aj.despesas.push(arrdesp)
                }
            }

            for (let ajd of objeto.ajudanteduplodespesas) {
                let arrdesp = [...i.despesas.filter(t => t.tipodado === 'ajudanteduplo' && t.dtviagemfinanceiro === ajd.dia)]
                if (arrdesp.length > 0) {
                    ajd.despesas.push(arrdesp)
                }
            }



            cargas.push(objeto)

            dados.push(i)

        }

        // variavel que filtra todas despesas adicionais e salva em um array para ser exibido em uma folha separada para logistica
        let despesasAdicionais = cargas.map(entry => {
            return {
                ...entry,
                motoristadespesas: entry.motoristadespesas.map(despesasDia => {
                    return {
                        ...despesasDia,
                        despesas: despesasDia.despesas.map(despesa =>
                            despesa.filter(d => d.tipodespesaviagem === "Outras Despesas")
                        ).filter(despesaArray => despesaArray.length > 0)
                    };
                }).filter(despesasDia => despesasDia.despesas.length > 0),

                ajudantedespesas: entry.ajudantedespesas.map(despesasDia => {
                    return {
                        ...despesasDia,
                        despesas: despesasDia.despesas.map(despesa =>
                            despesa.filter(d => d.tipodespesaviagem === "Outras Despesas")
                        ).filter(despesaArray => despesaArray.length > 0)
                    };
                }).filter(despesasDia => despesasDia.despesas.length > 0),

                ajudanteduplodespesas: entry.ajudanteduplodespesas.map(despesasDia => {
                    return {
                        ...despesasDia,
                        despesas: despesasDia.despesas.map(despesa =>
                            despesa.filter(d => d.tipodespesaviagem === "Outras Despesas")
                        ).filter(despesaArray => despesaArray.length > 0)
                    };
                }).filter(despesasDia => despesasDia.despesas.length > 0)
            };
        }).filter(entry => entry.motoristadespesas.length > 0 || entry.ajudantedespesas.length > 0 || entry.ajudanteduplodespesas.length > 0);

        // console.log('FILTRADOS', despesasAdicionais);

        let despesasSemAdicionais = cargas.map(entry => {
            return {
                ...entry,
                motoristadespesas: entry.motoristadespesas.map(despesasDia => {
                    return {
                        ...despesasDia,
                        despesas: despesasDia.despesas.map(despesa =>
                            despesa.filter(d => d.tipodespesaviagem !== "Outras Despesas")
                        ).filter(despesaArray => despesaArray.length > 0)
                    };
                }).filter(despesasDia => despesasDia.despesas.length > 0),

                ajudantedespesas: entry.ajudantedespesas.map(despesasDia => {
                    return {
                        ...despesasDia,
                        despesas: despesasDia.despesas.map(despesa =>
                            despesa.filter(d => d.tipodespesaviagem !== "Outras Despesas")
                        ).filter(despesaArray => despesaArray.length > 0)
                    };
                }).filter(despesasDia => despesasDia.despesas.length > 0),

                ajudanteduplodespesas: entry.ajudanteduplodespesas.map(despesasDia => {
                    return {
                        ...despesasDia,
                        despesas: despesasDia.despesas.map(despesa =>
                            despesa.filter(d => d.tipodespesaviagem !== "Outras Despesas")
                        ).filter(despesaArray => despesaArray.length > 0)
                    };
                }).filter(despesasDia => despesasDia.despesas.length > 0)
            };
        }).filter(entry => entry.motoristadespesas.length > 0 || entry.ajudantedespesas.length > 0 || entry.ajudanteduplodespesas.length > 0);

        // console.log('DESPESAS', despesasSemAdicionais);




        return (
            <Modal
                trigger={this.props.botao}
            //open={this.props.entidade.modalOpen}
            //onClose={(e) => this.props.handleClose(this.props.entidade)}
            >
                <Modal.Header>Recibo</Modal.Header>
                <Modal.Content image scrolling>

                    <Modal.Description>

                        <div ref={el => (this.componentRef = el)}>
                            {/* {console.log('CARGAS', cargas)} */}

                            {despesasSemAdicionais.map(ordem =>

                                <div>
                                    <If test={ordem.motoristadespesas.length > 0}>
                                        {ordem.motoristadespesas.map(despesadia =>
                                            <div>
                                                {/* {console.log(despesadia)} */}
                                                {
                                                    despesadia.despesas.map(despesaquebrada =>

                                                        <div style={{ border: '1px solid #000000', width: '500px', textAlign: 'center', margin: '5px 0px 0px 0px' }}>
                                                            {/* {console.log(despesaquebrada.find((a => a.tipodespesaviagem == 'Outras Despesas')))} */}
                                                            <b>Ordem de Carga:</b> {ordem.ordemcarga} | <b>Data:</b> {moment(despesadia.dia).format("DD/MM/YYYY")}
                                                            <br></br><b>Motorista:</b> {ordem.motorista}
                                                            <br></br>
                                                            <br></br>
                                                            <StyledTable celled>
                                                                <StyledTableHeader>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>Despesa</Table.HeaderCell>

                                                                        <Table.HeaderCell width={1}>Valor</Table.HeaderCell>

                                                                    </Table.Row>
                                                                </StyledTableHeader>

                                                                <StyledTableBody key="bodymotorista">
                                                                    {

                                                                        despesaquebrada.map(despesa => (

                                                                            <Table.Row key={'m' + despesa.idviagemdespesa}>
                                                                                <Table.Cell>{despesa.viagemdespesa} - {despesa.tipodespesaviagem}</Table.Cell>

                                                                                <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor)}</Table.Cell>
                                                                            </Table.Row>
                                                                        ))
                                                                    }

                                                                </StyledTableBody>

                                                                <StyledTableFooter>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell></Table.HeaderCell>


                                                                        <Table.HeaderCell><b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.calculaTotal(despesaquebrada))}</b></Table.HeaderCell>
                                                                    </Table.Row>
                                                                </StyledTableFooter>
                                                            </StyledTable>
                                                            <br></br><br></br><br></br>
                                                            ______________________________<br></br>
                                                            <b>{ordem.motorista}</b>
                                                            <br></br>
                                                            <br></br>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )}
                                    </If>



                                    <If test={ordem.ajudante != "<Sem Ajudante>"}>
                                        {ordem.ajudantedespesas.map(despesadia =>
                                            <div>

                                                {
                                                    despesadia.despesas.map(despesaquebrada =>

                                                        <div style={{ border: '1px solid #000000', width: '500px', textAlign: 'center', margin: '5px 0px 0px 0px' }}>
                                                            <b>Ordem de Carga:</b> {ordem.ordemcarga} | <b>Data:</b> {moment(despesadia.dia).format("DD/MM/YYYY")}
                                                            <br></br><b>Ajudante:</b> {ordem.ajudante}
                                                            <br></br>
                                                            <br></br>
                                                            <StyledTable celled>
                                                                <StyledTableHeader>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>Despesa</Table.HeaderCell>

                                                                        <Table.HeaderCell width={1}>Valor</Table.HeaderCell>

                                                                    </Table.Row>
                                                                </StyledTableHeader>

                                                                <StyledTableBody key="bodyajudante">
                                                                    {

                                                                        despesaquebrada.map(despesa => (

                                                                            <Table.Row key={'m' + despesa.idviagemdespesa}>
                                                                                <Table.Cell>{despesa.viagemdespesa} - {despesa.tipodespesaviagem}</Table.Cell>

                                                                                <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor)}</Table.Cell>
                                                                            </Table.Row>
                                                                        ))
                                                                    }

                                                                </StyledTableBody>

                                                                <StyledTableFooter>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell></Table.HeaderCell>


                                                                        <Table.HeaderCell><b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.calculaTotal(despesaquebrada))}</b></Table.HeaderCell>
                                                                    </Table.Row>
                                                                </StyledTableFooter>
                                                            </StyledTable>
                                                            <br></br><br></br><br></br>
                                                            ______________________________<br></br>
                                                            <b>{ordem.ajudante}</b>
                                                            <br></br>
                                                            <br></br>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )}
                                    </If>



                                    <If test={ordem.ajudanteduplo != "<Sem Ajudante>"}>
                                        {ordem.ajudanteduplodespesas.map(despesadia =>
                                            <div>

                                                {
                                                    despesadia.despesas.map(despesaquebrada =>

                                                        <div style={{ border: '1px solid #000000', width: '500px', textAlign: 'center', margin: '5px 0px 0px 0px' }}>
                                                            <b>Ordem de Carga:</b> {ordem.ordemcarga} | <b>Data:</b> {moment(despesadia.dia).format("DD/MM/YYYY")}
                                                            <br></br><b>Ajudante Duplo:</b> {ordem.ajudanteduplo}
                                                            <br></br>
                                                            <br></br>
                                                            <StyledTable celled>
                                                                <StyledTableHeader>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>Despesa</Table.HeaderCell>

                                                                        <Table.HeaderCell width={1}>Valor</Table.HeaderCell>

                                                                    </Table.Row>
                                                                </StyledTableHeader>

                                                                <StyledTableBody key="bodyajudanteduplo">
                                                                    {

                                                                        despesaquebrada.map(despesa => (

                                                                            <Table.Row key={'m' + despesa.idviagemdespesa}>
                                                                                <Table.Cell>{despesa.viagemdespesa} - {despesa.tipodespesaviagem}</Table.Cell>

                                                                                <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor)}</Table.Cell>
                                                                            </Table.Row>
                                                                        ))
                                                                    }

                                                                </StyledTableBody>

                                                                <StyledTableFooter>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell></Table.HeaderCell>


                                                                        <Table.HeaderCell><b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.calculaTotal(despesaquebrada))}</b></Table.HeaderCell>
                                                                    </Table.Row>
                                                                </StyledTableFooter>
                                                            </StyledTable>
                                                            <br></br><br></br><br></br>
                                                            ______________________________<br></br>
                                                            <b>{ordem.ajudanteduplo}</b>
                                                            <br></br>
                                                            <br></br>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )}
                                    </If>




                                </div>


                            )
                            }
                            {despesasAdicionais.length > 0 ? <span style={{ display: 'block', pageBreakBefore: 'always' }}>

                                {despesasAdicionais.map(ordem =>

                                    <div>
                                        <If test={ordem.motoristadespesas.length > 0}>
                                            {ordem.motoristadespesas.map(despesadia =>
                                                <div>
                                                    {/* {console.log(despesadia)} */}
                                                    {
                                                        despesadia.despesas.map(despesaquebrada =>

                                                            <div style={{ border: '1px solid #000000', width: '500px', textAlign: 'center', margin: '5px 0px 0px 0px' }}>
                                                                {/* {console.log(despesaquebrada.find((a => a.tipodespesaviagem == 'Outras Despesas')))} */}
                                                                <b>Ordem de Carga:</b> {ordem.ordemcarga} | <b>Data:</b> {moment(despesadia.dia).format("DD/MM/YYYY")}
                                                                <br></br><b>Motorista:</b> {ordem.motorista}
                                                                <br></br>
                                                                <br></br>
                                                                <StyledTable celled>
                                                                    <StyledTableHeader>
                                                                        <Table.Row>
                                                                            <Table.HeaderCell width={3}>Despesa</Table.HeaderCell>

                                                                            <Table.HeaderCell width={1}>Valor</Table.HeaderCell>

                                                                        </Table.Row>
                                                                    </StyledTableHeader>

                                                                    <StyledTableBody key="bodymotorista">
                                                                        {

                                                                            despesaquebrada.map(despesa => (

                                                                                <Table.Row key={'m' + despesa.idviagemdespesa}>
                                                                                    <Table.Cell>{despesa.viagemdespesa} - {despesa.tipodespesaviagem}</Table.Cell>

                                                                                    <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor)}</Table.Cell>
                                                                                </Table.Row>
                                                                            ))
                                                                        }

                                                                    </StyledTableBody>

                                                                    <StyledTableFooter>
                                                                        <Table.Row>
                                                                            <Table.HeaderCell></Table.HeaderCell>


                                                                            <Table.HeaderCell><b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.calculaTotal(despesaquebrada))}</b></Table.HeaderCell>
                                                                        </Table.Row>
                                                                    </StyledTableFooter>
                                                                </StyledTable>
                                                                <br></br><br></br><br></br>
                                                                ______________________________<br></br>
                                                                <b>{ordem.motorista}</b>
                                                                <br></br>
                                                                <br></br>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )}
                                        </If>



                                        <If test={ordem.ajudante != "<Sem Ajudante>"}>
                                            {ordem.ajudantedespesas.map(despesadia =>
                                                <div>

                                                    {
                                                        despesadia.despesas.map(despesaquebrada =>

                                                            <div style={{ border: '1px solid #000000', width: '500px', textAlign: 'center', margin: '5px 0px 0px 0px' }}>
                                                                <b>Ordem de Carga:</b> {ordem.ordemcarga} | <b>Data:</b> {moment(despesadia.dia).format("DD/MM/YYYY")}
                                                                <br></br><b>Ajudante:</b> {ordem.ajudante}
                                                                <br></br>
                                                                <br></br>
                                                                <StyledTable celled>
                                                                    <StyledTableHeader>
                                                                        <Table.Row>
                                                                            <Table.HeaderCell width={3}>Despesa</Table.HeaderCell>

                                                                            <Table.HeaderCell width={1}>Valor</Table.HeaderCell>

                                                                        </Table.Row>
                                                                    </StyledTableHeader>

                                                                    <StyledTableBody key="bodyajudante">
                                                                        {

                                                                            despesaquebrada.map(despesa => (

                                                                                <Table.Row key={'m' + despesa.idviagemdespesa}>
                                                                                    <Table.Cell>{despesa.viagemdespesa} - {despesa.tipodespesaviagem}</Table.Cell>

                                                                                    <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor)}</Table.Cell>
                                                                                </Table.Row>
                                                                            ))
                                                                        }

                                                                    </StyledTableBody>

                                                                    <StyledTableFooter>
                                                                        <Table.Row>
                                                                            <Table.HeaderCell></Table.HeaderCell>


                                                                            <Table.HeaderCell><b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.calculaTotal(despesaquebrada))}</b></Table.HeaderCell>
                                                                        </Table.Row>
                                                                    </StyledTableFooter>
                                                                </StyledTable>
                                                                <br></br><br></br><br></br>
                                                                ______________________________<br></br>
                                                                <b>{ordem.ajudante}</b>
                                                                <br></br>
                                                                <br></br>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )}
                                        </If>



                                        <If test={ordem.ajudanteduplo != "<Sem Ajudante>"}>
                                            {ordem.ajudanteduplodespesas.map(despesadia =>
                                                <div>

                                                    {
                                                        despesadia.despesas.map(despesaquebrada =>

                                                            <div style={{ border: '1px solid #000000', width: '500px', textAlign: 'center', margin: '5px 0px 0px 0px' }}>
                                                                <b>Ordem de Carga:</b> {ordem.ordemcarga} | <b>Data:</b> {moment(despesadia.dia).format("DD/MM/YYYY")}
                                                                <br></br><b>Ajudante Duplo:</b> {ordem.ajudanteduplo}
                                                                <br></br>
                                                                <br></br>
                                                                <StyledTable celled>
                                                                    <StyledTableHeader>
                                                                        <Table.Row>
                                                                            <Table.HeaderCell width={3}>Despesa</Table.HeaderCell>

                                                                            <Table.HeaderCell width={1}>Valor</Table.HeaderCell>

                                                                        </Table.Row>
                                                                    </StyledTableHeader>

                                                                    <StyledTableBody key="bodyajudanteduplo">
                                                                        {

                                                                            despesaquebrada.map(despesa => (

                                                                                <Table.Row key={'m' + despesa.idviagemdespesa}>
                                                                                    <Table.Cell>{despesa.viagemdespesa} - {despesa.tipodespesaviagem}</Table.Cell>

                                                                                    <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor)}</Table.Cell>
                                                                                </Table.Row>
                                                                            ))
                                                                        }

                                                                    </StyledTableBody>

                                                                    <StyledTableFooter>
                                                                        <Table.Row>
                                                                            <Table.HeaderCell></Table.HeaderCell>


                                                                            <Table.HeaderCell><b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.calculaTotal(despesaquebrada))}</b></Table.HeaderCell>
                                                                        </Table.Row>
                                                                    </StyledTableFooter>
                                                                </StyledTable>
                                                                <br></br><br></br><br></br>
                                                                ______________________________<br></br>
                                                                <b>{ordem.ajudanteduplo}</b>
                                                                <br></br>
                                                                <br></br>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )}
                                        </If>




                                    </div>


                                )
                                }


                            </span> : ""}



                        </div>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>

                    <ReactToPrint
                        trigger={() => <Button color='green'>Imprimir</Button>}
                        content={() => this.componentRef}
                        pageStyle="@media print { pre, blockquote, table, div { page-break-inside: avoid; border: 1px solid #ffff; } }"
                        onAfterPrint={(e) => this.props.handlePrint(this.props.datalist)}
                    />
                </Modal.Actions>

            </Modal >
        )

    }

}

ModalRecibos.defaultProps = {
    datalist: []
    , botao: {}
};

ModalRecibos.propTypes = {
    datalist: PropTypes.array.isRequired
    , botao: PropTypes.object.isRequired
    , handlePrint: PropTypes.func.isRequired
    //, handleClose: PropTypes.func.isRequired
};

export default ModalRecibos


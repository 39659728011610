import {
    OUVIDORIA_LIST,
    OUVIDORIA_LIST_LOADING
} from '../actions/ActionsTypes'
import * as Api from '../utils/API';


export const ouvidoriaListAction = (page) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: OUVIDORIA_LIST_LOADING, payload: true })
        console.log('caiu aquiu 1')
        Api.getOuvidoriaList(page).then(dados => {
            dispatch({ type: OUVIDORIA_LIST, payload: dados })
            dispatch({ type: OUVIDORIA_LIST_LOADING, payload: false })
            resolve(dados)
        }).catch(error => {
            dispatch({ type: OUVIDORIA_LIST_LOADING, payload: false })
            reject(error);
        })

    });



export const ouvidoriaSaveAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // dispatch({ type: PENTEFINO_LIST_LOADER, payload: true })
        console.log('caiu aquiu')
        Api.postOuvidoriaSave(dados).then(dados => {
            // dispatch({ type: OUVIDORIA_LIST, payload: dados })
            // dispatch({ type: PENTEFINO_LIST, payload: dados })
            resolve(dados)
        }).catch(error => {
            // dispatch({ type: PENTEFINO_LIST_LOADER, payload: false })
            reject(error);
        })

    });


export const ouvidoriaUpdateStatusAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {
        console.log('caiu aqi', dados)
        let data = {
            protocolo: dados
        }

        Api.postOuvidoriaStatusUpdate(data).then(dados => {
            resolve([])
        }).catch(error => {
            reject(error);
        })

    });



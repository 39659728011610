import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import sortBy from 'sort-by';

import moment from 'moment';
import 'moment/locale/pt-br'


import {
    Table, Button, Modal, Form, Segment, Message, Image, Header

} from 'semantic-ui-react'

import If from '../../utils/If';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styled from 'styled-components';




const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;




class Historico extends Component {


    state = { errordtinicial: 0, errordtfinal: 0 }

    constructor(props) {
        super(props);
    }


    componentWillReceiveProps() {




    }

    componentDidMount() {

        const datePickerInicial = document.getElementsByClassName("react-datepicker__input-container")[0];
        datePickerInicial.childNodes[0].setAttribute("readOnly", true);

        const datePickerFinal = document.getElementsByClassName("react-datepicker__input-container")[1];
        datePickerFinal.childNodes[0].setAttribute("readOnly", true);

    }

    handleChangeDateInicial = (value) => {

        let valor = null

        if (value != null) {
            valor = new Date(value)
        }

        this.props.corporativoUpdateAction({ target: { 'name': 'dtinicial', 'value': valor } })


    }

    handleChangeDateFinal = (value) => {
        let valor = null

        if (value != null) {
            valor = new Date(value)
        }

        this.props.corporativoUpdateAction({ target: { 'name': 'dtfinal', 'value': valor } })

    }

    handleConsultor = (e, { value }) => {
        this.props.corporativoUpdateAction({ target: { 'name': 'codconsultor', 'value': parseInt(value, 10) } })
    }

    handleCidade = (e, { value }) => {
        this.props.corporativoUpdateAction({ target: { 'name': 'codcid', 'value': parseInt(value, 10) } })
    }

    handlePalavra = (e, { value }) => {
        this.props.corporativoUpdateAction({ target: { 'name': 'palavra', 'value': value } })
    }

    handleParceiro = (e, { value }) => {

        if (isNaN(value.trim()) === false) {

            this.props.corporativoUpdateAction({ target: { 'name': 'codparc', 'value': parseInt(value, 10) } })
            this.props.corporativoUpdateAction({ target: { 'name': 'nomeparc', 'value': "" } })
        } else {
            this.props.corporativoUpdateAction({ target: { 'name': 'codparc', 'value': parseInt(0, 10) } })
            this.props.corporativoUpdateAction({ target: { 'name': 'nomeparc', 'value': value } })

        }

        //console.log(isNaN(value.trim()))

        //
    }





    handleBusca = () => {
        this.setState({ errordtinicial: 0 })
        this.setState({ errordtfinal: 0 })


        this.props.corporativoRemoveValidacao().then(result => {

            if (this.props.entidade.dtinicial === null) {
                this.props.corporativoAddValidacao(1, "Data Inicial", "É necessário preencher a Data Inicial")
                this.setState({ errordtinicial: 1 })
            }

            if (this.props.entidade.dtfinal === null) {
                this.props.corporativoAddValidacao(2, "Data Final", "É necessário preencher a Data Final")
                this.setState({ errordtfinal: 1 })
            }

            if (this.props.entidade.dtinicial != null && this.props.entidade.dtfinal != null) {
                if (this.props.entidade.dtinicial > this.props.entidade.dtfinal) {
                    this.props.corporativoAddValidacao(3, "Data Inicial", "A Data Inicial não pode ser maior que a Data Final")
                    this.setState({ errordtinicial: 1 })

                }
            }

            if (this.props.validacao.length === 0) {

                this.props.corporativoListSearchAction(this.props.RepresentanteBuscaEntity).then(dados => {

                })

            }

        })





    }





    render() {

        let { representantes, datalist, cidades } = this.props


        return (
            <div>
                <Segment secondary>

                    <Form loading={this.props.isSearching}>
                        <Form.Group widths='equal'>
                            <Form.Select name="codconsultor" options={representantes} defaultValue={this.props.RepresentanteBuscaEntity.codconsultor.toString()} label='Consultor'
                                onChange={this.handleConsultor.bind(this)}
                            />
                            <Form.Input name="nomeparc" fluid label='Parceiro' placeholder='Código ou Razão Social'
                                //onChange={this.props.consultoresUpdateAction}
                                onChange={this.handleParceiro.bind(this)}
                            />

                            <Form.Select name="codcid" options={cidades} defaultValue={this.props.RepresentanteBuscaEntity.codcid.toString()} label='Cidade'
                                onChange={this.handleCidade.bind(this)}
                            />

                            <Form.Input name="palavra" fluid label='Palavra Chave' placeholder='Palavra no campo Relato/Conversa'
                                onChange={this.handlePalavra.bind(this)} />

                        </Form.Group>
                        <Form.Group widths='eleven'>

                            <div className={this.state.errordtinicial === 0 ? "field" : "error field"}><label>Data Inicial</label>
                                <div>
                                    <DatePicker
                                        selected={this.props.entidade.dtinicial}
                                        onChange={this.handleChangeDateInicial.bind(this)}
                                        //onSelect={this.handleChangeDateInicial.bind(this)}
                                        isClearable={true}
                                        todayButton={"Hoje"}
                                        name="dtinicial"
                                        id="dtinicial"
                                        dateFormat="dd/MM/yyyy"
                                        required={true}

                                    />

                                </div>
                            </div>



                            <div className={this.state.errordtfinal === 0 ? "field" : "error field"}><label>Data Final</label>
                                <div>
                                    <DatePicker
                                        selected={this.props.entidade.dtfinal}
                                        onChange={this.handleChangeDateFinal.bind(this)}
                                        //onSelect={this.handleChangeDateFinal.bind(this)}
                                        isClearable={true}
                                        todayButton={"Hoje"}
                                        name="dtfinal"
                                        id="dtfinal"
                                        dateFormat="dd/MM/yyyy"
                                        required={true}

                                    />

                                </div>
                            </div>



                            <div className="field"><label>&nbsp;</label>

                                <Form.Button primary
                                    //loading={isSaving}
                                    onClick={this.handleBusca}
                                >Buscar</Form.Button>
                            </div>



                        </Form.Group>
                        {
                            (this.props.validacao.length > 0 ? (
                                <Message negative>
                                    {this.props.validacao.map(valida => (
                                        <p key={valida.id}>{valida.mensagem}</p>
                                    ))}

                                </Message>
                            ) : (
                                <div></div>
                            ))
                        }


                    </Form>
                </Segment>
                <If test={datalist.length > 0}>
                    {`Encontramos ${datalist.length} registro(s)`}

                    <Button basic color={'blue'} size="mini">Exportar para Excel</Button>
                </If>


                <StyledTable id="listFechamento" compact celled >
                    <StyledTableHeader>
                        <Table.Row>

                            <Table.HeaderCell>Consultor</Table.HeaderCell>
                            <Table.HeaderCell>Parceiro</Table.HeaderCell>
                            <Table.HeaderCell>CPF/CNPJ</Table.HeaderCell>
                            <Table.HeaderCell>Cidade</Table.HeaderCell>
                            <Table.HeaderCell>DT Visita</Table.HeaderCell>
                            <Table.HeaderCell>Vendedor</Table.HeaderCell>
                            <Table.HeaderCell width="1">

                            </Table.HeaderCell>

                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>

                        {datalist.map(item => (
                            <Table.Row key={"search_" + item.visitaid}>

                                <Table.Cell>{item.consultor} ({item.codconsultor})</Table.Cell>
                                <Table.Cell>{item.nomeparc} ({item.codparc})</Table.Cell>
                                <Table.Cell negative={(item.cgccpf == null ? true : false)}>{(item.cgccpf == null ? "Sem CPF/CNPJ" : item.cgccpf)}</Table.Cell>
                                <Table.Cell>{item.uf} - {item.nomecid}</Table.Cell>
                                <Table.Cell>{moment(item.dtvisita).format("DD/MM/YYYY")}</Table.Cell>
                                <Table.Cell>{(item.vendedor != null ? item.vendedor : "")} {(item.codvend != null ? `(${item.codvend})` : "")}</Table.Cell>

                                <Table.Cell textAlign="center">

                                    <Modal trigger={<Button basic icon={'search'} color={'blue'} size="mini" />}>
                                        <Modal.Header>Visita realizada em {moment(item.dtvisita).format("DD/MM/YYYY")} | {item.nomecid} - {item.uf}</Modal.Header>
                                        <Modal.Content>
                                            <Modal.Description>
                                                <Form>
                                                    <Form.Group widths='equal' color='gray'>
                                                        <div className="field"><label>Cliente/Parceiro ({item.codparc})</label>
                                                            <div className="ui fluid input">{item.nomeparc}</div>
                                                        </div>

                                                        <div className="field"><label>Consultor ({item.codconsultor})</label>
                                                            <div className="ui fluid input">{item.consultor}</div>
                                                        </div>

                                                        <div className="field">
                                                            <label>Vendedor ({item.codvend})</label>
                                                            <div>
                                                                {item.vendedor}
                                                            </div>
                                                        </div>

                                                        <div className="field"><label>KM Inicial</label>
                                                            <div>
                                                                {item.kminicial}
                                                            </div>
                                                        </div>

                                                        <div className="field"><label>KM Final</label>
                                                            <div>
                                                                {item.kmfinal}
                                                            </div>
                                                        </div>




                                                    </Form.Group>

                                                    <Form.Group widths='equal'>
                                                        <div className="field"><label>E-mail</label>
                                                            <div>
                                                                {item.email}
                                                            </div>
                                                        </div>

                                                        <div className="field">
                                                            <label>Telefone Principal:</label>
                                                            <div>{item.telefone}</div>
                                                        </div>

                                                        <div className="field">
                                                            <label>Telefone Secundário:</label>
                                                            <div>{item.fax}</div>

                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group widths='equal'>
                                                        <div className="field"><label>Ramo de atividade:</label>
                                                            <div>
                                                                {item.ramo}
                                                            </div>
                                                        </div>
                                                        <div className="field"><label>Produtos que trabalha:</label>
                                                            <div>
                                                                {item.produtostrabalha}
                                                            </div>
                                                        </div>
                                                        <div className="field"><label>Produtos que pretende trabalhar:</label>
                                                            <div>
                                                                {item.produtospretende}
                                                            </div>
                                                        </div>

                                                    </Form.Group>


                                                    <Form.Group widths='equal'>
                                                        <div className="field"><label>Financeiro:</label>
                                                            <div>
                                                                {(item.finreavaliacaodecredito === 'S' ? <div>Reavaliação de crédito: S</div> : "")}
                                                                {(item.finpagardividas === 'S' ? <div>Pagar dívidas: S</div> : "")}
                                                                {(item.ad_intadsite === 'S' ? <div>Adquirir ADSite: S</div> : "")}
                                                                {(item.ad_parceirofechou === 'S' ? <div>Parceiro fechou: S</div> : "")}
                                                            </div>
                                                        </div>
                                                        <div className="field"><label>Comercial:</label>
                                                            <div>
                                                                {(item.comfaltacontato === 'S' ? <div>Falta contato/retorno: S</div> : "")}
                                                                {(item.ad_intdisplay === 'S' ? <div>Interesse em Display: S</div> : "")}
                                                                {(item.ad_treinamentos === 'S' ? <div>Interesse em Treinamentos: S</div> : "")}
                                                            </div>
                                                        </div>
                                                        <div className="field">


                                                            <label>Logística:</label>
                                                            <div>
                                                                {(item.logmaterialdanificado === 'S' ? <div>Teve Material Danificado: S</div> : "")}
                                                                {(item.logentregaobrasobeescada === 'S' ? <div>Não entrega em Obras/Escadas: S</div> : "")}

                                                            </div>
                                                        </div>




                                                    </Form.Group>





                                                    <Form.Group widths='equal'>
                                                        <div className="field">
                                                            <div>



                                                            </div>
                                                        </div>


                                                        <div className="field"><label></label>
                                                            <div className="ui fluid input"></div>
                                                        </div>
                                                    </Form.Group>

                                                    <If test={item.mensagem != null}>
                                                        <Message color='red'>
                                                            <Message.Header>Financeiro</Message.Header>
                                                            <p>{item.mensagem}</p>
                                                        </Message>

                                                    </If>

                                                    <If test={item.msgpesquisamercado != null}>
                                                        <Message color='yellow'>
                                                            <Message.Header>Comercial/Pesquisa de Mercado</Message.Header>
                                                            <p>{item.msgpesquisamercado}</p>
                                                        </Message>

                                                    </If>

                                                    <If test={item.msgvendedor != null}>
                                                        <Message positive>
                                                            <Message.Header>Mensagem para o Vendedor</Message.Header>
                                                            <p>{item.msgvendedor}</p>
                                                        </Message>

                                                    </If>

                                                    <If test={item.conversa != null}>
                                                        <Message info>
                                                            <Message.Header>Relato/Conversa</Message.Header>
                                                            <p>{item.conversa}</p>
                                                        </Message>

                                                    </If>




                                                </Form>



                                            </Modal.Description>
                                        </Modal.Content>
                                    </Modal>

                                </Table.Cell>
                            </Table.Row>
                        ))}



                    </StyledTableBody>
                </StyledTable>
            </div>

        )
    }

}

Historico.defaultProps = {
    datalist: []
    , entidade: {}
    , representantes: []
    , cidades: []
};

Historico.propTypes = {
    datalist: PropTypes.array.isRequired
    , corporativoUpdateAction: PropTypes.func.isRequired
    , entidade: PropTypes.object.isRequired

    , corporativoAddValidacao: PropTypes.func.isRequired
    , corporativoRemoveValidacao: PropTypes.func.isRequired
    , validacao: PropTypes.array.isRequired
    , isSearching: PropTypes.bool.isRequired
    , representantes: PropTypes.array.isRequired
    , corporativoListSearchAction: PropTypes.func.isRequired
    , RepresentanteBuscaEntity: PropTypes.object.isRequired
    , cidades: PropTypes.array.isRequired

};

export default Historico

